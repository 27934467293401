import React, {useEffect, useState} from 'react'
import { Button, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap'
import {db} from '../../MY_LOGIN_AND_SIGNUP/db/config';
import ReportDialog from './ReportDialog';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { NavLink } from 'react-router-dom';

const Participants = ({handleClose, startIndex, match}) => {
  const [getReportData, setGetReportData] = useState([]);
  const [reportOpen, setReportOpen] = useState(false);
  // const [studentData, setstudentData] = useState([]);
  const [currentReport, setCurrentReport] = useState({});
  const [dataNotFound, setDataNotFound] = useState(false);
  
  // const [getId, setGetId] = useState(null)
  // console.log(match.params.id);

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  useEffect(() => {
    db.collection('attemptedQuiz')
    .where('quizID','==', match.params.id)
    .orderBy('lastAttemptedOn','desc')
    .get()
    .then((querySnapshot) => {
      let data = querySnapshot.docs.map((doc) => doc.data());
      console.log('attemptedQuiz',data);
      if(data.length === 0){
        setDataNotFound(true)
      }else{
        setGetReportData(data)
      }
    })
  }, [])

  return (
    <>
    {reportOpen && 
      <Dialog fullScreen open={reportOpen}  TransitionComponent={Transition}>
        <ReportDialog handleCloseButton={() =>{
          console.log('object');
          setReportOpen(false)
        } } currentReport={currentReport}/>
      </Dialog>
    }
    
    <div className="content" style={{ minWidth: '100%' }} >
      
        {/* <Row style={{ justifyContent: 'flex-end' }}>
          <IconButton edge="start" style={{ color: 'white' }} onClick={handleClose} aria-label="close">
              <CloseIcon />
          </IconButton>
        </Row> */}
        <Row>
          <Col md="12">
            <Card style={{margin:0}}>
              <CardHeader>
                <h5 className="title">Participants</h5>
              </CardHeader>
              <CardBody>
                    <>
                      
                      <Row>
                        <Col md="12">
                          <Table className="tablesorter">
                            <thead className="text-primary">
                              <tr>
                                <th>Student Name</th>
                                <th>Date & Time</th>
                                <th>Score</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                            {dataNotFound ? (
                              <tr>
                                <td colSpan="6">
                                  <div className="text-center my-4">
                                    <h2 style={{marginBottom: "0.5rem", lineHeight: "1.2"}}>
                                    Data not Found
                                    </h2>
                                  </div>
                                </td>
                              </tr>
                            ) : (
                              <>
                                {getReportData.map(item => {
                                  let time = new Date(
                                    item.lastAttemptedOn.seconds * 1000
                                  )
                                    .toString()
                                    .slice(0, 21);
                                return (
                                  <>
                                    <tr>
                                      <td>{item.name !== undefined && item.name}</td>
                                      <td>{time}</td>
                                      <td>{item.score}</td>
                                      <td>
                                        <Button color="info" style={{padding: "10px 20px"}} onClick={() => {
                                          setCurrentReport(item)
                                          setReportOpen(true);
                                        }}>
                                          View
                                        </Button>
                                      </td>
                                    </tr>
                                  </>
                                )})}
                              </>
                            )}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                      <NavLink to='/admin/quiz'>
                      <Button>
                        Back
                      </Button>
                      </NavLink>
                    </>
              </CardBody>
            </Card>
          </Col>
        </Row>
      
    </div>
    </>
  )
}

export default Participants
