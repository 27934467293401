import React, { useState, useEffect, useRef } from 'react'
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Modal,
    ModalBody,
    Table,
    ButtonGroup,
} from "reactstrap";
import { db } from '../../MY_LOGIN_AND_SIGNUP/db/config'
import { Edit, Delete } from '@material-ui/icons'

import KeyboardArrowLeftSharpIcon from '@material-ui/icons/KeyboardArrowLeftSharp';
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';
import IconButton from '@material-ui/core/IconButton';
import NotificationAlert from "react-notification-alert";
import QuizEditModal from './QuizEditModal'
import QuizModal from './Quiz_Modal'
import DeleteQuizQuestionModal from './Delete_Quiz_Question_Modal'
import styles from './quiz_Management.module.css'
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import QuizDialog from './QuizDialog';
import DeleteQuizModal from './DeleteQuizModal';
import { NavLink } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Quiz_Management() {
    const limit = 10;   
    const notification = useRef();
    const [deleteModal, setDeleteModal] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isDeleteQuizModal, setDeleteQuizModal] = useState(false)
    const [quizData, setquizData] = useState([])
    const [quizesData, setQuizesData] = useState([])
    const [currentQuestion, setcurrentQuestion] = useState('')
    const [currentQuiz, setCurrentQuiz] = useState('')
    const [startIndex, setStartIndex] = useState(1)
    const [endIndex, setEndIndex] = useState(0)
    const [active, setactive] = useState(false)
    const [open, setOpen] = useState(false);     


    

    const setQuizesDataWrapper = (data) => {
        if(typeof data == "function")
            data = data(quizesData);
            console.log('datatdatatadt quiz',data)
        setQuizesData(data.slice(0, limit));
    }

    const setQuesDataWrapper = (data) => {
        if(typeof data == "function")
            data = data(quizData);

        setquizData(data.slice(0, limit));
    }

    const deleteQuestion = (data) => {
        // console.log('data data,,, ',data)
        document.querySelector("#loading-indicator").style.display = "flex";

        db.collection('zSystemStore')
            .where('type', '==', 'quiz')
            .where('questions','array-contains',data).get().then(query=>{
                let deleteQuesData = query.docs.map(doc=>doc.data())
                console.log('deleteQuesData',deleteQuesData)
                if(deleteQuesData.length===0){

                    db.collection("Questions").doc(data).delete().then(() => {
                        document.querySelector("#loading-indicator").style.display = "none";

                        const afterDelete = quizData.filter(item => item.id !== data);
                        setquizData(afterDelete)
                        console.log('record deleted ')
                        notify("Question Deleted Successfully", false);
                    }).catch((error) => {
                        document.querySelector("#loading-indicator").style.display = "none";
                        notify(error.toString(), true);
                    })
                }else{
                    document.querySelector("#loading-indicator").style.display = "none";

                    notify("Please first delete quiz associated with the question", true)

                }
            }).catch(err=>{
                                
                notify(err.toString(), true);
                document.querySelector("#loading-indicator").style.display = "none";
                
              })
    }

    const getQuestions = () => {
        document.querySelector("#loading-indicator").style.display = "flex";

        db.collection('Questions').orderBy('createdAt','desc').limit(limit)
            .get()
            .then(querySnapshot => {
                let data = querySnapshot.docs.map(doc => {

                    return { ...doc.data(), id: doc.id }
                })
                document.querySelector("#loading-indicator").style.display = "none";

                setQuesDataWrapper(data)
                setStartIndex(1)

                setEndIndex(data.length)
                console.log('my user data is ', data)
            }).catch(err=>{
                                
                notify(err.toString(), true);
                document.querySelector("#loading-indicator").style.display = "none";
                console.log(err.toString())
              })
    }

    const nextSet = () => {
        // console.log(lastDoc)
        // if (quizData.length === limit) {
            // setClicked(true)
            document.querySelector("#loading-indicator").style.display = "flex";

            db.collection('Questions').orderBy('createdAt','desc')
                .startAfter(quizData[quizData.length-1].createdAt).limit(limit).get()
                .then(querySnapshot => {
                    let data = querySnapshot.docs.map(doc => {

                        return { ...doc.data(), id: doc.id }
                    })
                    if(data.length !== 0){
                        document.querySelector("#loading-indicator").style.display = "none";

                        setQuesDataWrapper(data)
                        setStartIndex(endIndex + 1)
                        setEndIndex(prev => prev + data.length)
                        console.log('my user data is next ', data)
                    }else{
                        document.querySelector("#loading-indicator").style.display = "none";
        
                        notify('End Of the list', true)

                    }
                }).catch(err=>{
                                
                    document.querySelector("#loading-indicator").style.display = "none";
                    notify(err.toString(), true);
                    console.log(err.toString())
                  })
        // } else {
        //     notify('End Of the list', true)
        // }
    }

    const prevSet = () => {
        document.querySelector("#loading-indicator").style.display = "flex";

        db.collection('Questions').orderBy('createdAt')
            .startAfter(quizData[0].createdAt).limit(startIndex<=limit+1 ? limit+10 : limit).get()
            .then(querySnapshot => {
                let data = querySnapshot.docs.map(doc => {

                    return { ...doc.data(), id: doc.id }
                })

                if (data.length !== 0) {
                    document.querySelector("#loading-indicator").style.display = "none";

                    data = data.reverse()

                    console.log('data length',data.length)
                    
                    console.log('startIndex',startIndex,'endIndex',endIndex)
                    setStartIndex(prev => prev - data.length)
                    setEndIndex(prev => prev - quizData.length)
                    setQuesDataWrapper(data)
                    console.log('my user data is prev', data)
                } else {
                
                    // setClicked(false)
                    document.querySelector("#loading-indicator").style.display = "none";

                    console.log('Can not go back')
                }
            }).catch(err=>{
                                
                notify(err.toString(), true);
                document.querySelector("#loading-indicator").style.display = "none";
                console.log(err.toString())
              })
    }

    
    const nextQuizSet = () => {
    //    if (quizesData.length === limit) {
            // setClickedQuiz(true)
            document.querySelector("#loading-indicator").style.display = "flex";

            db.collection('zSystemStore')
            .where('type', '==', 'quiz')
            .orderBy('createdAt', 'desc').startAfter(quizesData[quizesData.length-1].createdAt).limit(limit).get()
                .then(querySnapshot => {
                    let data = querySnapshot.docs.map(doc => {

                        return { ...doc.data(), id: doc.id }
                    })
                    if(data.length !== 0){
                        document.querySelector("#loading-indicator").style.display = "none";

                        setQuizesDataWrapper(data)
                        setStartIndex(endIndex + 1)
                        setEndIndex(prev => prev + data.length)
                        console.log('my user data is ', data)
                    }else {
                        document.querySelector("#loading-indicator").style.display = "none";

                        notify('End Of the list', true)
                    }
                }).catch(err=>{
                                
                    document.querySelector("#loading-indicator").style.display = "none";
                    notify(err.toString(), true);
                    console.log(err.toString())
                  })
        // } else {
        //     notify('End Of the list', true)
        // }
    }

    const prevQuizSet = () => {
      document.querySelector("#loading-indicator").style.display = "flex";

      db.collection('zSystemStore')
      .where('type', '==', 'quiz')
      .orderBy('createdAt',)
      .startAfter(quizesData[0].createdAt).limit(startIndex<=limit+1 ? limit+10 : limit).get()
            // .endBefore(quizData[0].questionTitle).get()
            .then(querySnapshot => {
                let data = querySnapshot.docs.map(doc => {

                    return { ...doc.data(), id: doc.id }
                })

                if (data.length !== 0) {
                    document.querySelector("#loading-indicator").style.display = "none";

                    data = data.reverse()

                    console.log('data length',data.length)

                    console.log('startIndex',startIndex,'endIndex',endIndex)
                    setStartIndex(prev => prev - data.length)
                    setEndIndex(prev => prev - quizesData.length)
                    setQuizesDataWrapper(data)
                    console.log('my user data is ', data)
                } else {
                    // setClicked(false)
                    document.querySelector("#loading-indicator").style.display = "none";

                    console.log('Can not go back')
                }
            }).catch(err=>{
                                
                document.querySelector("#loading-indicator").style.display = "none";
                notify(err.toString(), true);
                console.log(err.toString())
              })
    }

    const notify = (msg, err) => {
        let color = err === true ? 'danger' : 'success'
        let options = {
            type: color,
            place: "tr",
            message: (
                <div>
                    <div>
                        <b>{err === true ? 'Error' : 'Success'}</b> -{msg}.
              </div>
                </div>
            ),
            icon: "tim-icons icon-bell-55",
            autoDismiss: 6
        };
        if(notification.current){
            notification.current.notificationAlert(options)
        }
    }

    // const filterSearch = (data) => {
    //     console.log(data)
    //     document.querySelector("#loading-indicator").style.display = "flex";

    //     db.collection('Questions').where('subject', '==', data).get()
    //         .then((querySnapshot) => {
    //             let data = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
    //             // doc.data() is never undefined for query doc snapshots
    //             document.querySelector("#loading-indicator").style.display = "none";
                
    //             setquizData(data)

    //             console.log(" => ", data);

    //         }
    //         ).catch(err=>{
                                
    //             document.querySelector("#loading-indicator").style.display = "none";
    //             notify(err.toString(), true);
    //             console.log(err.toString())
    //           })

    // }

    const getQuiz = () => {
        document.querySelector("#loading-indicator").style.display = "flex";

        db.collection('zSystemStore')
        .where('type', '==', 'quiz')
        .orderBy('createdAt', 'desc').limit(limit)
        .get()
        .then(querySnapshot => {
            let data = querySnapshot.docs.map(doc => {

                return { ...doc.data(), id: doc.id }
            })
            document.querySelector("#loading-indicator").style.display = "none";
            // data = data.filter((item) => item.type === 'quiz')
            console.log('quiz list', data);
            setQuizesDataWrapper(data)
            setStartIndex(1)

            setEndIndex(data.length)
            
            // setEndIndex(data.length)
            console.log('Quiz data is ', data)
        }).catch(err=>{
                                
            document.querySelector("#loading-indicator").style.display = "none";
            notify(err.toString(), true);
            console.log(err.toString())
          })


    }

    const handleClickOpen = () => {

        setOpen(true);
    };

    const handleClose = () => {
            setOpen(false);
    };

    useEffect(() => {
        getQuiz()

    }, [])


    const deleteQuiz = (data) => {
        // console.log('beforedelete',data)
        document.querySelector("#loading-indicator").style.display = "flex";


        db.collection("zSystemStore").doc(data).delete().then(() => {
            document.querySelector("#loading-indicator").style.display = "none";
            
            const afterQuizDelete = quizesData.filter(item => item.id !== data);
            // console.log('after delete',afterDelete)
            setQuizesDataWrapper(afterQuizDelete)
            console.log('record deleted ')
            notify("Quiz Deleted Successfully", false);
        }).catch((error) => {
            document.querySelector("#loading-indicator").style.display = "none";

            notify(error.toString(), true);

            console.log('this is error ', error)
        }).catch(err=>{
                                
            document.querySelector("#loading-indicator").style.display = "none";
            notify(err.toString(), true);
            console.log(err.toString())
          })
    }

    
    // const search=()=>{
    //     // console.log('search',searchQuestion)
        
    //     if(searchQuestion==''){
    //         console.log('field Empty')
    //         notify('Search Field Can not be empty',true)
            
    //     }else if(searchQuestion.length<3){

    //         console.log('below 3')
    //         notify('Search atleast 4 words',true)

            
    //     }else{
    //         document.querySelector("#loading-indicator").style.display = "flex";

    //         indexForQues.search(searchQuestion).then(result=>{

    //             console.log('result',result.hits)
                
    //             if(result.hits.length===0){
    //                 document.querySelector("#loading-indicator").style.display = "none";

    //                 notify('Search exact Question Title ',true)

    //             }else{
    //                 document.querySelector("#loading-indicator").style.display = "none";
                    
    //                 setQuesDataWrapper(result.hits)
    //                 setreload(true)

    //             }
    //         }).catch(err=>{
    //             document.querySelector("#loading-indicator").style.display = "none";
    //             notify(err.toString(), true);
    //             console.log(err.toString())
    //           })
                                
    //     }
    // }

    // const searchQuizFun = () => {
    //     if(searchQuiz==''){
    //         console.log('field Empty')
    //         notify('Search Field Can not be empty',true)
            
    //     }else if(searchQuiz.length<3){

    //         console.log('below 3')
    //         notify('Search atleast 4 words',true)

            
    //     }else{
    //         document.querySelector("#loading-indicator").style.display = "flex";

    //         indexForQuiz.search(searchQuiz).then(result=>{

    //             console.log('result quiz',result.hits)
                
    //             if(result.hits.length===0){
    //                 document.querySelector("#loading-indicator").style.display = "none";

    //                 notify('Search exact Question Title ',true)

    //             }else{
    //                 document.querySelector("#loading-indicator").style.display = "none";
    //                 setQuizesDataWrapper(result.hits)
    //                 setreload(true)

    //             }
    //         }).catch(err=>{
    //             document.querySelector("#loading-indicator").style.display = "none";
    //             notify(err.toString(), true);
    //             console.log(err.toString())
    //           })
                                
    //     }
    // }
    
    // const emptyCall=()=>{
    //     if(reload){

    //         if(searchQuestion.length >= 1 && searchQuestion.length < 4){
    //             getQuestions()
    //             setreload(false)
    //         }
    //         if(searchQuiz.length >= 1 && searchQuiz.length < 4){
    //             getQuiz()
    //             setreload(false)
    //         }
    //     }
    // }

    return (
        <div className="content">
            <div className="react-notification-alert-container">
                <NotificationAlert ref={notification} />
            </div>
            {/* {participantOpen === true && 
                <Participants startIndex={startIndex} handleClose={() => setParticipantOpen(false)}/>
            } */}
             
            
            <Row>
                <Col md="12">
                    <Card >
                        <CardHeader className={styles.card_header}>
                            <Col >
                                <ButtonGroup>
                                    <Button
                                        className={!active ? "data" : "btn-simple data"}
                                        color="info"
                                        size="sm"
                                        onClick={() => {
                                            setactive(prev => !prev)
                                            console.log(active)
                                            getQuiz()

                                        }}
                                    >

                                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                            QUIZ
                                            </span>
                                        <span className="d-block d-sm-none">
                                            <i className="tim-icons icon-single-02" />
                                        </span>
                                    </Button>
                                    <Button
                                        className={!active ? "btn-simple data" : "data" }

                                        color="info"
                                        size="sm"
                                        onClick={() => {
                                            setactive(prev => !prev)
                                            console.log(active)
                                            getQuestions()
                                        }}
                                    >
                                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                            Questions
                                            </span>
                                        <span className="d-block d-sm-none">
                                            <i className="tim-icons icon-gift-2" />
                                        </span>
                                    </Button>
                                </ButtonGroup>
                            </Col>
                            {!active ? (
                                <Button color='info' onClick={() => {
                                    setCurrentQuiz('')
                                    handleClickOpen()
                                }}>Add Quiz</Button>

                            ) : (
                                    <Button color='info' onClick={() => setIsModalVisible(true)}>Add Questions</Button>
                                )
                            }

                        </CardHeader>




                        {!active ? (
                            <CardBody>
                                <Modal isOpen={isDeleteQuizModal} color='primary' style={{ display: 'flex', flex: 1, top: 0, width: 250, backgroundColor: '#132639', objectFit: 'fill' }} >
                                    <ModalBody style={{ width: '100%' }} size='sm'>
                                        <DeleteQuizModal
                                            deleteQuiz={() => deleteQuiz(currentQuiz)}
                                            setDeleteQuizModal={setDeleteQuizModal}
                                        ></DeleteQuizModal>
                                    </ModalBody>
                                </Modal>
                                <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>

                                    <QuizDialog startIndex={startIndex} currentQuiz={currentQuiz} setQuizesData={setQuizesDataWrapper} notifi={notify} handleClose={handleClose} />
                                </Dialog>
                                <Row style={{justifyContent: 'flex-end'}}>
                                    {/* <Col sm={4}>
                                        <Input type="text" name="search" placeholder="Search Quiz" 
                                         onChange={(e)=>{setSearchQuiz(e.target.value)
                                            emptyCall()
                                        }}/>
                                    </Col>
                                    <Col style={{ paddingLeft: '0px' }} sm={2}>
                                        <Button style={{ margin: "0px", padding: '6px 10px' }} color="info"
                                        onClick={searchQuizFun}
                                        >
                                            <SearchIcon /></Button>

                                    </Col> */}
                                    <Col style={{ display: 'flex', justifyContent: 'flex-end' }} sm={6}>
                                        <p style={{ position: 'relative', top: '8px', marginRight: '15px' }}> {startIndex <= 1 ? 1 : startIndex} - {endIndex}</p>
                                        <div style={{ position: 'relative', top: '-5px', marginRight: '20px' }}>

                                            {
                                                startIndex > 1 &&
                                                <IconButton style={{ outline: 'none' }}>
                                                    <KeyboardArrowLeftSharpIcon style={{ color: 'white' }}
                                                        onClick={() => {

                                                            prevQuizSet()
                                                        }} />
                                                </IconButton>
                                            }
                                            <IconButton style={{ outline: 'none' }}
                                                onClick={() => nextQuizSet()}>
                                                <KeyboardArrowRightSharpIcon style={{ color: 'white' }} />

                                            </IconButton>
                                        </div>

                                    </Col>

                                </Row>
                                <br></br>
                                <Table className="tablesorter" >
                                    <thead className="text-primary">
                                        <tr>
                                            <th>Quiz Title</th>
                                            <th>No of Questions</th>
                                            <th style={{paddingLeft:40}}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {quizesData.map(data => {
                                            // console.log('quiztest',data)
                                            return (
                                                <tr key={data.id}>
                                                    <td>{data.title}</td>
                                                    <td style={{ paddingLeft: '15px' }}>{data.questions && data.questions.length}</td>
                                                    <td style={{ cursor: 'pointer' }}>
                                                        <Edit style={{color:'#247cf7'}}
                                                            onClick={() => {
                                                                setCurrentQuiz(data)
                                                                handleClickOpen()
                                                            }}
                                                        />
                                                        <Delete
                                                            style={{ color: 'red' }}
                                                            onClick={() => {
                                                                setCurrentQuiz(data.id)
                                                                setDeleteQuizModal(prev => !prev)
                                                            }}
                                                        />
                                                        <NavLink to={'/admin/participants/'+data.id}>
                                                            <Button color= "info" style={{marginLeft: 10, padding: "10px 20px"}}>
                                                                Participants
                                                            </Button>
                                                        </NavLink>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        }
                                    </tbody>

                                </Table>


                            </CardBody>
                        ) : (

                                <CardBody >
                                    <Row style={{justifyContent: 'flex-end'}}>
                                        {/* <Col sm={4}>
                                            <Input type="text" name="searchQuestion" placeholder="Search Question" 
                                            onChange={(e)=>{setSearchQuestion(e.target.value)
                                                emptyCall()
                                            }} />
                                        </Col>
                                        <Col style={{ paddingLeft: '0px' }} sm={2}>
                                            <Button style={{ margin: "0px", padding: '6px 10px' }} color="info">
                                                <SearchIcon onClick={search}/>
                                            </Button>

                                        </Col> */}

                                        {/* <Col sm={3}>

                                    
                                        </Col> */}

                                        <Col style={{ display: 'flex', justifyContent: 'flex-end' }} sm={6}>
                                            <p style={{ position: 'relative', top: '8px', marginRight: '15px' }}> {startIndex <= 1 ? 1 : startIndex} - {endIndex}</p>
                                            <div style={{ position: 'relative', top: '-5px', marginRight: '20px' }}>

                                                {
                                                    startIndex > 1 &&
                                                    <IconButton style={{ outline: 'none' }}
                                                        onClick={() => {

                                                            prevSet()
                                                        }}
                                                        >
                                                        <KeyboardArrowLeftSharpIcon style={{ color: 'white' }}
                                                             />
                                                    </IconButton>
                                                }
                                                <IconButton style={{ outline: 'none' }}
                                                    onClick={() => nextSet()}>
                                                    <KeyboardArrowRightSharpIcon style={{ color: 'white' }} />

                                                </IconButton>
                                            </div>
                                            {/* <Dropdown isOpen={dropdownOpen} toggle={toggle} >
                                                <DropdownToggle style={{ margin: '0px', padding: '6px 10px' }} className="nav-link" color='info' caret>
                                                    <FilterListIcon />
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                    <DropdownItem style={{ color: 'black' }} header>Filter By Subject</DropdownItem>
                                                    <DropdownItem style={{ color: 'black' }} onClick={() => getQuestions()}>All</DropdownItem>

                                                    <DropdownItem style={{ color: 'black' }} onClick={() => filterSearch('English')}>English</DropdownItem>
                                                    <DropdownItem style={{ color: 'black' }} onClick={() => filterSearch('Math')}>Maths</DropdownItem>
                                                    <DropdownItem style={{ color: 'black' }} onClick={() => filterSearch('Science')}>Science</DropdownItem>
                                                    {/* <DropdownItem>Science</DropdownItem> */}

                                                {/* </DropdownMenu>
                                            </Dropdown> */} 
                                        </Col>

                                    </Row>
                                    <br></br>
                                    <Modal isOpen={isModalVisible} style={{ display: 'flex', flex: 1, top: -200, minWidth: '85%' }} >
                                        <ModalBody className={styles.create__quiz_modal_body} >
                                            <QuizModal notify={notify} startIndex={startIndex} setquizData={setQuesDataWrapper} onClick={() => {
                                                setIsModalVisible(false)
                                            }}></QuizModal>
                                        </ModalBody>
                                    </Modal>
                                    <Table className="tablesorter" >
                                        <thead className="text-primary">
                                            <tr>
                                                <th style={{width: 1500}}> Questions Tile</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {quizData.map(data => {
                                                // console.log(quizData)
                                                // let status = user.status && [];
                                                return (
                                                    <tr key={data.id}>
                                                        <td style={{width: 1500}}>{data.questionTitle}</td>
                                                        <td style={{ cursor: 'pointer' }}>
                                                            <Edit style={{color:'#247cf7'}}
                                                                onClick={() => {
                                                                    setcurrentQuestion(data)
                                                                    setEditModal(true)
                                                                }}
                                                            />
                                                            <Delete onClick={() => {
                                                                setcurrentQuestion(data.id)
                                                                setDeleteModal(true)
                                                            }}
                                                                style={{ color: 'red' }}
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </tbody>

                                    </Table>
                                </CardBody>
                            )
                        }
                    </Card>
                </Col>
            </Row>
            <Modal isOpen={deleteModal} color='primary' style={{ display: 'flex', flex: 1, top: 0, width: 250, backgroundColor: '#132639', objectFit: 'fill' }} >
                <ModalBody style={{ width: '100%' }} size='sm'>
                    <DeleteQuizQuestionModal
                        deleteQuestion={() => deleteQuestion(currentQuestion)}
                        setDeleteModal={setDeleteModal}
                    ></DeleteQuizQuestionModal>
                </ModalBody>
            </Modal>
            <Modal isOpen={editModal} style={{ display: 'flex', flex: 1, top: -200, minWidth: '85%' }} >
                <ModalBody className={styles.create__quiz_modal_body} >
                    <QuizEditModal notify={notify}
                        setquizData={setquizData} currentQuestion={currentQuestion} onClick={() => {
                            setEditModal(false)
                        }}>

                    </QuizEditModal>
                </ModalBody>
            </Modal>

        </div>
    )
}

export default Quiz_Management
