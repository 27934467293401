import { db } from 'MY_LOGIN_AND_SIGNUP/db/config';
import React from 'react';
import { Button } from 'reactstrap';
import * as firebase from 'firebase'

    const storage = firebase.storage()

function Book_Delete_Step1({deletedBook,notify,setBoookData,onClick}) {
    console.log('my deleted book',deletedBook)
//    useEffect(()=>{
    // let ref=storage.refFromURL(deletedBook.bookFileUrl)
    // let anotherref = ref.child()
    //    let ss=storage.bucket().file(ref)
    //    storage.
        // console.log('referance',ref,anotherref)
    // },[])
    return(
        <div>
        <p>Do you want to delete these book</p>
        <div style={{display:"flex",flexDirection:'row'}}>
        <Button color='info' onClick={()=>{
            onClick()
            db.collection("zSystemStore").doc(deletedBook.itemID).delete().then(()=>{
                let refPDF=''
                if(deletedBook.bookFileUrl) storage.refFromURL(deletedBook.bookFileUrl)
                let refPDFMob=''
                if(deletedBook.bookFileUrlMobile) storage.refFromURL(deletedBook.bookFileUrlMobile)
                let refCover = storage.refFromURL(deletedBook.bookcover)
                if(refPDF !== '') refPDF.delete().then(res=>console.log('PDF deleted'))
                if(refPDFMob !== '') refPDFMob.delete().then(res=>console.log('PDF for mobile deleted'))
                refCover.delete().then(res=>console.log('Book Cover deleted'))

                notify('Book Deleted Successfully',false)
                setBoookData(prev=>prev.filter(item=>item.itemID!==deletedBook.itemID))
                console.log('book is successfully deleted ')

                
            }).catch((error)=>{
                notify(error.toString(),true)

                console.log('this is error ', error)
            })
        }}>Yes</Button>
        <Button onClick={()=>{
            
            onClick()
            
        }}>No</Button>
        </div>
        </div>
    )
}

export default Book_Delete_Step1;