import React from 'react'
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';

const ReportDialog = ({handleCloseButton, currentReport}) => {
  console.log(currentReport);

  return (
    <div style={{ backgroundColor: '#1f1e2e', height: '100%', overflowY: 'scroll' }} >
      <div style={{ padding: '5px 20px' }}>
        <Row style={{ justifyContent: 'flex-end' }}>
          {/* <NavLink> */}
            <IconButton edge="start" style={{ color: 'white' }} onClick={handleCloseButton} aria-label="close">
                <CloseIcon />
            </IconButton>
          {/* </NavLink> */}
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h5 className="title">Report</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12">
                    <Table className="tablesorter">
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Answer</th>
                          <th>Correct/Wrong</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentReport && currentReport.result && currentReport.result.map((data) => {
                          return (
                          <tr>
                            <td>{data.title}</td>
                            <td>{data.correctAns}</td>
                            <td>{data.isCorrect ? "Correct" : "Wrong"}</td>
                          </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ReportDialog
