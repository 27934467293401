import React, { useEffect, useState, useRef } from 'react'
import NotificationAlert from "react-notification-alert";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Table,
    Progress,
} from "reactstrap";
import { db } from '../../MY_LOGIN_AND_SIGNUP/db/config'
import { useForm } from "react-hook-form";

import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeftSharpIcon from '@material-ui/icons/KeyboardArrowLeftSharp';
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';


function EnquiryTable() {
    const limit = 10;

    const notification = useRef();
    const [progressVisible, setProgressVisible] = useState(true)
    const [progressValue, setProgressValue] = useState(8)

    const [transactionData, setTransactionData] = useState([])


    const [startIndex, setStartIndex] = useState(1)
    const [endIndex, setEndIndex] = useState(0)


    const headers = [
        { label: "Name", key: "from" },
        { label: "Email", key: "to" },
        { label: "Query", key: "createdAt" }
    ];

    const notify = (msg, err) => {
        let color = err === true ? "danger" : "success"
        let options = {
            type: color,
            place: "tr",
            message: (
                <div>
                    <div>
                        <b>{err === true ? 'Error' : 'Success'}</b> -{msg}.
                    </div>
                </div>
            ),
            icon: "tim-icons icon-bell-55",
            autoDismiss: 6
        };
        if(notification.current){
            notification.current.notificationAlert(options)
        }
    }

    const getTransaction = () => {
        setProgressValue(99)

        db.collection('enquiry').orderBy('createdOn', 'desc').limit(limit).get().then((querySnapshot) => {
            let data = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
            
            data = data.map(item => {
                const time = new Date(item.createdOn.seconds * 1000).toLocaleString();

                return ({
                    id: item.id,
                    email: item.email,
                    message: item.message,
                    name: item.name,
                    createdOn: time,
                    createdAt: item.createdOn,
                    phone:item.phone !== undefined && item.phone !== '' ? item.phone : 'N/A' 
                })

            })
            console.log(data);
            setProgressVisible(false)

            setTransactionData(data)
            setEndIndex(data.length)

        })
    }
    
    
    const nextSet = () => {
        if (transactionData.length === limit) {
        // setClicked(true)
        document.querySelector("#loading-indicator").style.display = "flex";

        db.collection('enquiry')
            .orderBy('createdOn','desc').startAfter(transactionData[transactionData.length-1].createdAt).limit(limit).get()
            .then(querySnapshot => {
            let data = querySnapshot.docs.map(doc => {

                return doc.data()
            })
            console.log('dataaaa', data)
            if (data.length !== 0) {
                // console.log("should go", data[2].title)
                document.querySelector("#loading-indicator").style.display = "none";
                
                data = data.map(item => {
                    const time = new Date(item.createdOn.seconds * 1000).toLocaleString();

                    return ({
                        id: item.id,
                        email: item.email,
                        message: item.message,
                        name: item.name,
                        createdOn: time,
                        createdAt: item.createdOn,
                        phone:item.phone !== undefined && item.phone !== '' ? item.phone : 'N/A' 

                    })

                })
                setTransactionData(data)
                setStartIndex(endIndex + 1)
                setEndIndex(prev => prev + data.length)
                // console.log('my user data is ', data)

            } else {
            document.querySelector("#loading-indicator").style.display = "none";

                notify('End Of the list', true)
            }

            }).catch(err=>{
                                    
            document.querySelector("#loading-indicator").style.display = "none";
            notify(err.toString(), true);
            console.log(err.toString())
            })
        } else {
          notify('End Of the list', true)
        }
    }

    const prevSet = () => {
        document.querySelector("#loading-indicator").style.display = "flex";

        db.collection('enquiry')
        .orderBy('createdOn')
        .startAfter(transactionData[0].createdAt).limit(limit).get()
        .then(querySnapshot => {
            let data = querySnapshot.docs.map(doc => {

            return doc.data()
            })
            if (data.length !== 0) {
                document.querySelector("#loading-indicator").style.display = "none";
                data = data.map(item => {
                    const time = new Date(item.createdOn.seconds * 1000).toLocaleString();

                    return ({
                        id: item.id,
                        email: item.email,
                        message: item.message,
                        name: item.name,
                        createdOn: time,
                        createdAt: item.createdOn,
                        phone:item.phone !== undefined && item.phone !== '' ? item.phone : 'N/A' 
                })

                })
                data = data.reverse()

                setStartIndex(prev => prev - data.length)
                setEndIndex(prev => prev - transactionData.length)
                setTransactionData(data)
                console.log('my user data is ', data)
            } else {
                document.querySelector("#loading-indicator").style.display = "none";
                console.log('Can not go back')
            }
        }).catch(err=>{
                                    
            document.querySelector("#loading-indicator").style.display = "none";
            notify(err.toString(), true);
            console.log(err.toString())
        })
    }



    useEffect(() => {
        getTransaction()
    }, [])


    return (
        <>
      {progressVisible ? 
        <>
            <Progress color='info' value={progressValue} style={{ height: 4 }} />
            <div className="content">
            </div>
        </>
        :
        <div className="content">
            <div className="react-notification-alert-container">
                <NotificationAlert ref={notification} />
            </div>

            <Row>
                <Col md="12">
                    <Card >
                        <CardHeader style={{ display: "flex", flexDirection: 'row', justifyContent: 'center' }}>
                            
                        <Row style={{ justifyContent: 'flex-end', width: '100%', paddingBottom: '8px' }}>
                            <Col sm={6}>
                                <CardTitle tag="h4" style={{ marginRight: 'auto', marginLeft: 'auto', fontWeight: 300, color:'#fff' }}>Enquiry List</CardTitle>
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: 'flex-end' }} sm={6}>
                            <p style={{ position: 'relative', top: '8px', marginRight: '15px' }}>
                                {startIndex <= 1 ? 1 : startIndex} - {endIndex}
                            </p>
                            <div style={{ position: 'relative', top: '-5px', marginRight: '20px' }}>
                            {
                                startIndex > 1 &&
                                <IconButton style={{ outline: 'none' }}
                                onClick={() => {

                                    prevSet()
                                }}
                                >
                                <KeyboardArrowLeftSharpIcon style={{ color: 'white' }}
                                    
                                />
                                </IconButton>

                            }
                                <IconButton style={{ outline: 'none' }}
                                onClick={() => nextSet()}
                                >
                                <KeyboardArrowRightSharpIcon style={{ color: 'white' }} />

                                </IconButton>
                            </div>
                            </Col>

                        </Row>

                            {/* <Button color='info' onClick={() => setIsModalVisible(true)}>Create User <Add /></Button> */}
                        </CardHeader>
                        <CardBody>
                            <Table className="tablesorter" >
                                <thead className="text-primary">
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Mobile No</th>
                                        <th>Submitted At</th>
                                        <th>Query</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transactionData.map(item => {
                                        return (
                                            <tr key={item.id}>
                                                <td style={{ minWidth:110 }}>{item.name}</td>
                                                <td>{item.email}</td>
                                                <td>{item.phone}</td>
                                                <td style={{ minWidth:110 }}>{item.createdOn}</td>
                                                <td>{item.message}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
        }
        </>
    )
}

export default EnquiryTable;
