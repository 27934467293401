/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import { Nav, NavLink as ReactstrapNavLink } from "reactstrap";

import { db } from '../../MY_LOGIN_AND_SIGNUP/db/config'
import * as firebase from 'firebase'

var ps;

//test data
const options = [
  // { label: 'Dashboard', value: 'Dashboard' },
  { label: 'User Management', value: 'User_Management' },
  { label: 'Student Management', value: 'Student_Management' },
  { label: 'Book Management', value: 'Book_Management' },
  { label: 'Enquiry', value: 'Enquiry' },
  { label: 'Reports', value: 'Reports' },
  { label: 'Topic Analytics', value: 'TopicAnalytics' }
];

const sideOptions = ["Student_Management","Book_Management","Quiz_Management","Topic_Management","Enquiry","Topic_Analytics"]

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
    // this.getUsers.bind(this)
    // this.state.bind(this)
    this.state = { currentPermission: [] };
  }

  // verifies if routeName is the one active (in browser input)
  // check =this.props
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  

  getUsers = () => {

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        let uid = user.uid;
        db.collection('zSystemUsers').doc(uid)
          .get()
          .then(querySnapshot => {
            let data = querySnapshot.data()
            // let currentPermission = sideOptions.filter((item)=> data.permissions.find(v => v.value == item));
            this.setState({ currentPermission: sideOptions });
            
          })

      } else {
        // User is signed out.
        // ...
      }
    })
  }


  componentDidMount() {
    this.getUsers();
    // console.log(this.props);
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  linkOnClick = () => {
    document.documentElement.classList.remove("nav-open");
  };






  render() {

    const { bgColor, routes, rtlActive, logo } = this.props;

    let sidebar_component = this.state.currentPermission.map((data) => {
      return (routes.filter((routesdata) => (
        data == routesdata.id)
      ))
    })

    


    let logoImg = null;
    let logoText = null;
    if (logo !== undefined) {
      if (logo.outterLink !== undefined) {
        logoImg = (
          <a
            href={logo.outterLink}
            className="simple-text logo-mini"
            target="_blank"
            onClick={this.props.toggleSidebar}
          >
            <div className="logo-img">
              <img src={logo.imgSrc} alt="react-logo" />
            </div>
          </a>
        );
        logoText = (
          <a
            href={logo.outterLink}
            className="simple-text logo-normal"
            target="_blank"
            onClick={this.props.toggleSidebar}
          >
            {logo.text}
          </a>
        );
      } else {
        logoImg = (
          <Link
            to={logo.innerLink}
            className="simple-text logo-mini"
            onClick={this.props.toggleSidebar}
          >
            <div className="logo-img">
              <img src={logo.imgSrc} alt="react-logo" />
            </div>
          </Link>
        );
        logoText = (
          <Link
            to={logo.innerLink}
            className="simple-text logo-normal"
            onClick={this.props.toggleSidebar}
          >
            {logo.text}
          </Link>
        );
      }
    }
    return (
      <div className="sidebar" data={bgColor}>
        <div className="sidebar-wrapper" ref="sidebar">
          {logoImg !== null || logoText !== null ? (
            <div className="logo" style={{textAlign: "center"}}>
              {/* {logoImg} */}
              {logoText}
            </div>
          ) : null}
          <Nav>
             {sidebar_component.map((prop, key) => {
              if (prop[0].redirect) return null;
              return (
                <li
                  className={
                    this.activeRoute(prop[0].path) +
                    (prop[0].pro ? " active-pro" : "")
                  }
                  key={key}
                >
                  <NavLink
                    to={prop[0].layout + prop[0].path}
                    className="nav-link"
                    // activeClassName="active"

                    onClick={this.props.toggleSidebar}
                  >
                    <i className={prop[0].icon} />
                    <p>{rtlActive ? prop[0].rtlName : prop[0].name}</p>
                  </NavLink>
                </li>
              );
            })}
            <li
              className="active-pro"
            >
            </li>
          </Nav>
        </div>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  rtlActive: false,
  bgColor: "primary",
  routes: [{}]
};

Sidebar.propTypes = {
  // if true, then instead of the routes[i].name, routes[i].rtlName will be rendered
  // insde the links of this component
  rtlActive: PropTypes.bool,
  bgColor: PropTypes.oneOf(["primary", "blue", "green"]),
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the text of the logo
    text: PropTypes.node,
    // the image src of the logo
    imgSrc: PropTypes.string
  })
};

export default Sidebar;
