import React, { useState } from "react";
import { useForm } from 'react-hook-form'

import * as firebase from 'firebase'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";
import { db } from '../MY_LOGIN_AND_SIGNUP/db/config'

const functions = firebase.functions()

function Add_Student({ notify, setModal }) {
  // const [sendEmail, setSendEmail] = useState(false)
  // const [sendSMS, setSendSMS] = useState(false)
  const [error, setError] = useState('')
  const { register, handleSubmit, errors } = useForm()

  const onSubmit= (data) => {

    // console.log('data<><><><><><',data,data.countryCode == '+91' && data.mobile.length !== 10 ,data.countryCode == '+27' && data.mobile.length !== 9 )
    if ((data.countryCode == '+91' && data.mobile.length !== 10 ) || (data.countryCode == '+27' && data.mobile.length !== 9)) {
      notify(`Invalid mobile no`, true)

      return
    } else {
      setError('')
      const studentData = {
        firstname: data.firstname,
        lastname: data.lastname,
        mobile: data.mobile,
        address: data.address,
        email: data.email,
        password: data.password,
        // sendEmail: sendEmail,
        forceResetPassword: true,
        // sendSMS: sendSMS,
        profilePic: '',
        status: 'Active',
        disabled: false,
        countryCode: data.countryCode

      }
      const addStudentUser = functions.httpsCallable('addStudentUser');
      document.querySelector("#loading-indicator").style.display = "flex";
      addStudentUser(studentData).then(result => {

        if(result.data.hasError){
          document.querySelector("#loading-indicator").style.display = "none";
          notify(`${result.data.message.errorInfo.message}`, true)
        }else{
          const _uniqueID = result.data.user.uid;
          db.collection('zSystemStudents').doc(_uniqueID.toString()).set({
          firstname: data.firstname,
          lastname: data.lastname,
          mobile: data.mobile,
          address: data.address,
          email: data.email,
          password:data.password,
          // sendEmail:sendEmail,
          // sendSMS:sendSMS,
          forceResetPassword: true,
          createdFrom:'Admin',
          profilePic:'',
          status: 'Active',
          _uniqueID,
          disabled:false,
          createdAt:firebase.firestore.FieldValue.serverTimestamp(),
          lastUpdatedAt:firebase.firestore.FieldValue.serverTimestamp(),
          }).then(function () {
            notify("Student Created Successfully", false)
            document.querySelector("#loading-indicator").style.display = "none";
            setModal(false);
          }).catch(function (error) {
            notify(`${error.toString()}`, true)
            document.querySelector("#loading-indicator").style.display = "none";
          })
          console.log(result);
        }
      }).catch((err) => {
        notify(err.toString(), true);
        document.querySelector("#loading-indicator").style.display = "none";
        console.log(err.toString())
      })
      
      // firebase.auth().createUserWithEmailAndPassword(data.email,data.password).then((user)=>{
      //   let _uniqueID = user.user.uid
      //     db.collection('zSystemStudents').doc(_uniqueID).set({
      //       firstname: data.firstname,
      //       lastname: data.lastname,
      //       mobile: data.mobile,
      //       enrollment: val.toString(),
      //       address: data.address,
      //       email: data.email,
      //       password:data.password,
      //       profilePic:'',
      //       status: 'onboarding',
      //       _uniqueID,
      //       disabled:false
    
      //     }).then(function () {
      //       console.log('documnet successfully inserted')
    
      //     }).catch(function (error) {
      //       console.log('this is error', error)
      //     })
        
      // console.log('user created' ,doc)

      // })      
    }
  }
  



  return (
    <>
      <div className="content" style={{ width: 1150, }}>
        <Row style={{}}>
          <Col md="8">
            <Card style={{ border: '2px solid white' }}>
              <CardHeader>
                <h5 className="title">Add Student</h5>
              </CardHeader>
              <CardBody>
                <Form style={{ paddingRight: 10 }} onSubmit={handleSubmit(onSubmit)}
                  
                >
                  <Row>

                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>First Name</label>
                        <Input
                          innerRef={register({ required: true })}
                          name='firstname'
                          placeholder="First Name"
                          type="text"
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Last Name</label>
                        <Input
                          innerRef={register}
                          name='lastname'
                          placeholder="Last Name"
                          type="text"

                        />
                      </FormGroup>
                    </Col>
                    {/* <Col className="pl-md-1" md="4">
                        <FormGroup>
                          <label>
                            Username
                          </label>
                          <Input placeholder="username" type="text" name='username' required innerRef={register({required:true,minLength:6})}/>
                        </FormGroup>
                      </Col> */}
                  </Row>
                  {/* <Row>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Email</label>
                          <Input
                          innerRef={register({required:true,})}
                            name='email'
                            placeholder="user@user.com"
                            type="email"
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-md-1" md="6">
                        <FormGroup>
                          <label>Password</label>
                          <Input
                            innerRef={register({required:true,minLength:6})}
                            required
                            name='password'
                            type="password"
                            placeholder='Password'
                          />
                        </FormGroup>
                      </Col>
                    </Row> */}
                 
                  <Row>

                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Email</label>
                        <Input
                          innerRef={register({ required: true })}
                          name='email'
                          placeholder="Email"
                          type="email"
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Password</label>
                        <Input
                          innerRef={register({ required: true, minLength: 8 })}
                          name='password'
                          placeholder="Password"
                          type="password"

                        />
                        {errors.password && errors.password.type === 'minLength' && (
                        <span style={{fontSize:'10px',position:'absolute',bottom:'-20px',color:'red'}} >Password should be atleast 8 digits.</span>
                      )}
                      </FormGroup>
                    </Col>
                    {/* <Col className="pl-md-1" md="4">
                        <FormGroup>
                          <label>
                            Username
                          </label>
                          <Input placeholder="username" type="text" name='username' required innerRef={register({required:true,minLength:6})}/>
                        </FormGroup>
                      </Col> */}
                  </Row>
                  <Row>
                    {/* <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Enrollment number</label>
                        <Input
                          innerRef={register({ required: true })}
                          name='enrollment'
                          placeholder="Enrollment number"
                          type='text'
                          required
                        />
                        
                      {errors.enrollment && errors.enrollment.type === 'required' &&
                        <span style={{fontSize:'10px',position:'absolute',bottom:'-20px',color:'red'}}  >Please enter enrollment number</span>
                      }
                      </FormGroup>
                    </Col> */}
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Mobile</label>
                        <Row>
                          <Col md="4">
                            <Input
                              defaultValue={'+27'}
                              
                              innerRef={register({required:true,})}
                              name='countryCode'
                              placeholder="Status"
                              type="select"
                              required
                            > 
                              <option>+27</option>
                              <option>+91</option>
                              
                            </Input>
                          </Col>
                          <Col md="8">
                            <Input
                              innerRef={register({ minLength: 9, maxLength: 10, required: true, })}
                              name='mobile'
                              placeholder="Mobile"
                              type='number'
                              required
                            />
                          </Col>
                        </Row>
                        
                      {errors.mobile && errors.mobile.type !== 'required' &&
                        <span style={{fontSize:'10px',position:'absolute',bottom:'-20px',color:'red'}}  >Please Enter Valid Mobile No.</span>
                      }
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Address</label>
                        <Input
                          innerRef={register({ required: true, })}
                          name='address'
                          placeholder="Address"
                          type='textarea'
                          required
                        />
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                      </FormGroup>
                    </Col>
                  </Row>

                  

                  <Row>

                    <Col className="pr-md-1" md="6" style={{paddingLeft: "70px"}}>
                      {/* <div >
                        <input 
                          name="sendEmail"
                          type="checkbox"
                          value={sendEmail}
                          onChange={(e) => setSendEmail(!sendEmail)}
                          style={{marginRight:"10px"}}
                        />
                        <span style={{fontSize: "15px", color: "rgba(255,255,255,0.6)"}}>Send Invitation mail</span>
                      </div> */}
                    </Col>

                    <Col className="pr-md-1" md="6" style={{paddingLeft: "70px"}}>
                      {/* <div>
                        <input 
                          name="sendSMS"
                          type="checkbox"
                          value={sendSMS}
                          onChange={(e) => setSendSMS(!sendSMS)}
                          style={{marginRight:"10px"}}
                        />
                        <span style={{fontSize: "15px", color: "rgba(255,255,255,0.6)"}}>Send Invitation SMS</span>
                      </div> */}
                    </Col>
                  </Row>
                  <br></br>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>

                    {/* {visible===false? <Button className="btn-fill" color="info" type="submit">
                    Submit
                  </Button> : <Button className="btn-fill" color="info" onClick={onClick}>Done</Button>}
                  {visible===false && <Button className="btn-fill"  onClick={onClick}>
                    Cancel
                  </Button>} */}
                    <Button color='info' type='submit'>Submit</Button>
                    <Button onClick={()=> setModal(false)}>Back</Button>
                  </div>
                </Form>
              </CardBody>

            </Card>
          </Col>

        </Row>
      </div>
    </>
  );
}


export default Add_Student;
