import React, {useState, useEffect, useRef} from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Row,
    Label,
    Col,
} from "reactstrap";
import styles from './quiz_modal.module.css'
import { useForm } from "react-hook-form";
import firebase from 'firebase'
import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";


function Quiz_Modal({ onClick, notify,startIndex, setquizData }) {
    const { handleSubmit, register, } = useForm();
    const [questionImage, setQuestionImage] = useState();
    const [questionImageToShow, setQuestionImageToShow] = useState();
    const [imageUrl, setimageUrl] = useState('');



    const onSubmit = (values, e) => {
        console.log('quiz modal......', values)
        const options = [
            values.optionA,
            values.optionB,
        ]

        if (values.optionC !== '') options.push(values.optionC)
        if (values.optionD !== '') options.push(values.optionD)
        if (values.optionE !== '') options.push(values.optionE)
        if (values.optionF !== '') options.push(values.optionF)

        console.log('quiz modal......after options   ', options)
        const data = {
            questionTitle: values.questionTitle,
            options: options,
            correctOption: parseInt(values.correctOption),
            // difficulty: parseInt(values.difficulty),
            // subject: values.subject,
            createdAt:firebase.firestore.FieldValue.serverTimestamp(),
            image: imageUrl

        } 


        db.collection('Questions').add(data).then(res => {
            // db.collection('Questions')
            // .get()
            // .then(querySnapshot => {
            //     let data = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
            console.log('startIndex',startIndex)
            if(startIndex <= 1){

                setquizData(prev => ([{ ...data, id: res.id }, ...prev]))
            }

            notify("Question Added Successfully", false)
            e.target.reset();
            setQuestionImageToShow()
            // })
        }).catch(err => {
            notify(err.toString(), false)
            console.log('err', err)
        })


        // db.collection('Questions').add(data).then(() => {
        //     notify("Question Added Successfully")
        //     setquizData((previousData) => ([...previousData, data]))
        //     console.log('documnet successfully inserted',data);
        //     setalert(true);
        //     e.target.reset();
        // }).catch((error) => {
        //     console.log('this is error', error)
        // })



    };

    const uploadQuestionImages = (event) => {
        console.log(event.target.files[0])
        console.log("my image name", event.target.files[0].name)
        if (!event.target.files[0]) {
          return
        }
        if(event.target.files[0].type=== 'image/jpeg' ||event.target.files[0].type=== 'image/png' ){    
          try {
            let date = new Date().valueOf();
    
            var uploadTask = firebase.storage().ref().child(`topicCover/${date}${event.target.files[0].name}`).put(event.target.files[0])
            uploadTask.on('state_changed', function (snapshot) {
              var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('upload is ' + progress + '% done');
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED:
                  console.log('upload is paused')
                  break;
                case firebase.storage.TaskState.RUNNING:
                  console.log('upload is running')
                  break
              }
            }, function (error) {
              console.log('this is error', error)
            }, function () {
              uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                notify('Book Cover Uploaded Successfully', false)
      
                console.log('File available at ', downloadURL)
                setimageUrl(downloadURL)
                setQuestionImageToShow(downloadURL)
              }).catch(err => {
                notify(err.toString(), true)
      
              })
            })
      
          } catch (error) {
            notify(error.toString(), true)
      
            console.log('my error', error)
          }
          setQuestionImage(event.target.files[0].name)
        }else {
          notify('Please select only .jpeg/.png file', true)
    
        }
    }

    return (
        <div className="content" >

            <Row >
                <Col md="12">
                    <Card style={{ margin: 0 }}>
                        <CardHeader>
                            <h5 className="title">Quiz Management</h5>
                        </CardHeader>
                        <CardBody>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup row>
                                    <Col sm={7} style={{ borderRight: '1px solid #29384a' }}>
                                        <FormGroup row>

                                            <Label className={styles.headlabel} sm={2} >Question</Label>
                                            <Col sm={10}>
                                                <Input style={{ minHeight: '295px' }} innerRef={register({ required: true })} type="textarea" name="questionTitle" placeholder="Please Enter Question" required />
                                            </Col>
                                        </FormGroup>
                                        <br></br>

                                        <FormGroup row>
                                            <Label style={{ padding: '9px' }} className={styles.headlabel} sm={2}>Correct Answer</Label>
                                            <Col sm={10}>
                                                <Input innerRef={register({ required: true })} type="select" name="correctOption" required>
                                                    <option value={0}>A</option>
                                                    <option value={1}>B</option>
                                                    <option value={2}>C</option>
                                                    <option value={3}>D</option>
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        {/* <FormGroup row>
                                            <Label style={{ padding: '9px' }} className={styles.headlabel} sm={2}>Difficulty Level</Label>
                                            <Col sm={10}>
                                                <Input innerRef={register({ required: true })} type="select" name="difficulty" required>
                                                    <option value={1}>Easy</option>
                                                    <option value={2}>Medium</option>
                                                    <option value={3}>Diffculty</option>
                                                </Input>
                                            </Col>
                                        </FormGroup> */}
                                        <FormGroup row>
                                            <Label className={styles.headlabel} sm={2}>Image</Label>
                                            <Col sm={10}>
                                                <label style={{ cursor: 'pointer', color: 'white', fontSize: 16 }}>{questionImage === undefined ? <Button ><CloudUploadIcon /></Button> : <Button><CloudDoneIcon /></Button>}</label>
                                                <Input
                                                    style={{ cursor: 'pointer' }}
                                                    innerRef={register({ required: false, accept: '.jpeg,.png' })}
                                                    name='bookcover'
                                                    placeholder='Upload'
                                                    onChange={uploadQuestionImages}
                                                    type='file'
                                                    accept='.jpeg ,.png'
                                                />
                                                {questionImageToShow && <img src={questionImageToShow} alt="" style={{ width: 40, height: 55, marginTop: 5, marginLeft:20 }} />}
                                            </Col>
                                        </FormGroup>

                                    </Col>
                                    <Col sm={5}>

                                        <FormGroup row>
                                            <Label className={styles.textalign} sm={2}>A.</Label>
                                            <Col sm={9}>
                                                <Input innerRef={register({ required: true })} type="textarea" name="optionA" placeholder="Option A" required />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label className={styles.textalign} sm={2}>B.</Label>
                                            <Col sm={9}>
                                                <Input innerRef={register({ required: true })} type="textarea" name="optionB" placeholder="Option B" required />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label className={styles.textalign} sm={2}>C.</Label>
                                            <Col sm={9}>
                                                <Input innerRef={register({ required: false })} type="textarea" name="optionC" placeholder="Option C" />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label className={styles.textalign} sm={2}>D.</Label>
                                            <Col sm={9}>
                                                <Input innerRef={register({ required: false })} type="textarea" name="optionD" placeholder="Option D" />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label className={styles.textalign} sm={2}>E.</Label>
                                            <Col sm={9}>
                                                <Input innerRef={register({ required: false })} type="textarea" name="optionE" placeholder="Option E" />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label className={styles.textalign} sm={2}>F.</Label>
                                            <Col sm={9}>
                                                <Input innerRef={register({ required: false })} type="textarea" name="optionF" placeholder="Option F" />
                                            </Col>
                                        </FormGroup>

                                    </Col>
                                </FormGroup>
                                <br></br>
                                {/* <FormGroup row>
                                <Label className={styles.headlabel} sm={2}>Correct Answer</Label>
                                <Col sm={9}>
                                    <Input innerRef={register({ required: true })} type="select" name="correctOption" required>
                                        <option value={0}>A</option>
                                        <option value={1}>B</option>
                                        <option value={2}>C</option>
                                        <option value={3}>D</option>
                                    </Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label className={styles.headlabel} sm={2}>Difficulty Level</Label>
                                <Col sm={9}>
                                    <Input innerRef={register({ required: true })} type="select" name="difficulty" required>
                                        <option value={1}>Level 1</option>
                                        <option value={2}>Level 2</option>
                                        <option value={3}>Level 3</option>
                                        <option value={4}>Level 4</option>
                                        <option value={5}>Level 5</option>
                                    </Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label className={styles.headlabel} sm={2}>Subject</Label>
                                <Col sm={9}>
                                    <Input innerRef={register({ required: true })} type="select" name="subject" required>
                                        <option>Science</option>
                                        <option>Math</option>
                                        <option>English</option>
                                        <option>Social Science</option>
                                    </Input>
                                </Col>
                            </FormGroup> */}
                                <Button color='info' type='submit'>Submit</Button>
                                <Button onClick={onClick}> Back</Button>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div >
    )
}

export default Quiz_Modal
