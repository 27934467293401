import React,{useState, useEffect} from 'react';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import {db} from '../MY_LOGIN_AND_SIGNUP/db/config';

const StudentReport = ({currentReportId, handleCloseButton}) => {
  // console.log(currentReportId);
  const [reportData, setReportdata] = useState([]);
  const [dataNotFound, setDataNotFound] = useState(false);


  useEffect(() => {
    db.collection('attemptedQuiz').where('userID','==',currentReportId)
    .get()
    .then((query) => {
        let data = query.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        if(data.length === 0){
          setDataNotFound(true);
        } else {
          setReportdata(data)
        }
      });
  }, []);

  return (
    <div style={{ backgroundColor: '#1f1e2e', height: '100%' }} >
      <div style={{ padding: '5px 20px' }}>
        <Row style={{ justifyContent: 'flex-end' }}>
          <IconButton edge="start" style={{ color: 'white' }} onClick={handleCloseButton} aria-label="close">
              <CloseIcon />
          </IconButton>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h5 className="title">Report</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12">
                    <Table className="tablesorter">
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Score</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataNotFound ? (
                          <tr>
                            <td colSpan="6">
                              <div className="text-center my-3">
                                <h2 style={{marginBottom: "0.5rem", lineHeight: "1.2"}}>
                                Data not Found
                                </h2>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <>
                            {reportData && reportData.map((data) => {
                              let time = new Date(
                                data.lastAttemptedOn.seconds * 1000
                              )
                                .toString()
                                .slice(0, 21);
                              return(
                                <tr>
                                  <td>{data.title}</td>
                                  <td>{data.score}</td>
                                  <td>{time}</td>
                                </tr>
                              )
                            })}
                          </>
                        )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default StudentReport
