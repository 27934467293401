import React from 'react'
import {Button} from "reactstrap";

const PackageDelete=({deletePackage,setDeleteModal})=> {
    
    const deleteRecord= ()=>{
        // console.log('data...',data,'id',data)
        setDeleteModal(false)
        deletePackage()
    
    }

    const onclick= ()=>{
        setDeleteModal(false)

    }
    return (
    <div>
        <p >Do you want to delete this Topic</p>
        <div style={{display:'flex',flexDirection:'row'}}>
        <Button color='info' onClick={deleteRecord}>Yes</Button>
        <Button  onClick={onclick}>No</Button>
        </div>
    </div>
    )
}

export default PackageDelete
