import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import React, { useState, useRef } from "react";
import { useForm } from 'react-hook-form'
import { NavLink,  useHistory } from "react-router-dom";
import firebase from 'firebase'
import PDF from '../../components/PDF/PDF'

import NotificationAlert from "react-notification-alert";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";
import { db } from '../../MY_LOGIN_AND_SIGNUP/db/config'
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Book_Add_Step1({ enroll }) {
  const history = useHistory()
  const [bookFileName, setBookFileName] = useState()
  const [bookFileNameMob, setBookFileNameMob] = useState()

  const [bookImage, setBookImage] = useState()
  const [addformVisible, setAddFormVisible] = useState(false)
  const [imageUrl, setimageUrl] = useState('')
  const [bookCover, setBookCover] = useState()
  const [bookFileUrl, setBookFileUrl] = useState()
  const [same, setSame] = useState(false)

  const [currentFile, setCurrentFile] = useState({})
  const [currentFileMob, setCurrentFileMob] = useState({})

  const [clickedFile, setClickedFile] = useState('')

  const [open, setOpen] = useState(false);

  const { register, handleSubmit, errors } = useForm()



  // console.log("this is my  Location", location)
  // console.log("this is from  form as props", enroll)

  const notifi = useRef();

  const notify = (msg, err) => {
    // console.log('notify')
    let color = err == false ? 'success' : 'danger'
    let options = {
      type: color,
      place: "tr",
      message: (
        <div>
          <div>
            <b>{err == false ? 'Success' : 'Error'}</b> -{msg}.
          </div>
        </div>
      ),
      icon: "tim-icons icon-bell-55",
      autoDismiss: 6
    };
    if(notifi.current){
      notifi.current.notificationAlert(options)
    }
    // notification(options);
  }

  const handleClickOpen = () => {

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const selectBookCover = (event) => {
    console.log(event.target.files[0])
    console.log("my image name", event.target.files[0].name)
    if (!event.target.files[0]) {
      return
    }
    if(event.target.files[0].type=== 'image/jpeg' ||event.target.files[0].type=== 'image/png' ){

      try {
        let date = new Date().valueOf();

        var uploadTask = firebase.storage().ref().child(`booksCover/${date}${event.target.files[0].name}`).put(event.target.files[0])
        uploadTask.on('state_changed', function (snapshot) {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('upload is ' + progress + '% done');
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
              console.log('upload is paused')
              break;
            case firebase.storage.TaskState.RUNNING:
              console.log('upload is running')
              break
          }
        }, function (error) {
          console.log('this is error', error)
        }, function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            notify('Book Cover Uploaded Successfully', false)
  
            console.log('File available at ', downloadURL)
            setimageUrl(downloadURL)
            setBookImage(downloadURL)
          }).catch(err => {
            notify(err.toString(), true)
  
          })
        })
  
      } catch (error) {
        notify(error.toString(), true)
  
        console.log('my error', error)
      }
      setBookCover(event.target.files[0].name)
    }else {
      notify('Please select only .jpeg/.png file', true)

    }
  }

  const selectBookFile = (event) => {
    console.log(event.target.files[0])
    console.log(event.target.files[0].name)
    if (!event.target.files[0]) {
      return
    }
    if (event.target.files[0].type === 'application/pdf') {

      console.log('file.......', event.target.files)
      setCurrentFile(event.target.files[0])
      setBookFileName(event.target.files[0].name)
      try {
        let date = new Date().valueOf();

        var uploadTask = firebase.storage().ref().child(`books/${date}${event.target.files[0].name}`).put(event.target.files[0])
        uploadTask.on('state_changed', function (snapshot) {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('upload is ' + progress + '% done');
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
              console.log('upload is paused')
              break;
            case firebase.storage.TaskState.RUNNING:
              console.log('upload is running')
              break
          }
        }, function (error) {
          console.log('this is error', error)
        }, function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            notify('Book Uploaded Successfully', false)

            console.log('File available at ', downloadURL)
            setBookFileUrl(downloadURL)
          }).catch(err => {
            notify(err.toString, true)
          })
        })
      } catch (error) {
        notify(error.toString, true)

        console.log('my error', error)
      }
    } else {
      notify('Please select PDF file', true)

    }
  }


  // const selectBookFileMob = (event) => {
  //   console.log(event.target.files[0])
  //   console.log(event.target.files[0].name)
  //   if (!event.target.files[0]) {
  //     return
  //   }
  //   if (event.target.files[0].type === 'application/pdf') {

  //     setCurrentFileMob(event.target.files[0])
  //     setBookFileNameMob(event.target.files[0].name)
  //     try {
  //       let date = new Date().valueOf();

  //       var uploadTask = firebase.storage().ref().child(`books/${date}${event.target.files[0].name}`).put(event.target.files[0])
  //       uploadTask.on('state_changed', function (snapshot) {
  //         var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //         console.log('upload is ' + progress + '% done');
  //         switch (snapshot.state) {
  //           case firebase.storage.TaskState.PAUSED:
  //             console.log('upload is paused')
  //             break;
  //           case firebase.storage.TaskState.RUNNING:
  //             console.log('upload is running')
  //             break
  //         }
  //       }, function (error) {
  //         console.log('this is error', error)
  //       }, function () {
  //         uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
  //           notify('Book Uploaded Successfully', false)

  //           console.log('File available at ', downloadURL)
  //           setBookFileUrlMob(downloadURL)
  //         }).catch(err => {
  //           notify(err.toString, true)
  //         })
  //       })
  //     } catch (error) {
  //       notify(error.toString, true)

  //       console.log('my error', error)
  //     }
  //   } else {
  //     notify('Please select PDF file', true)

  //   }
  // }

  return (
    <>
      <div className="content" style={{}}>
        <div className="react-notification-alert-container">
          <NotificationAlert ref={notifi} />
        </div>
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
          <PDF handleClose={handleClose} clickedFile={clickedFile} currentFileMob={currentFileMob} currentFile={currentFile} />
        </Dialog>
        <Row style={{}}>
          <Col md="11">
            <Card style={{}}>
              <CardHeader>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: 'center' }}>
                  <h4 className="title" style={{ fontWeight: 400 }}>Create New Book</h4>
                </div>
              </CardHeader>
              <CardBody>
                <Form style={{ paddingRight: 10 }} onSubmit={handleSubmit((data, e) => {

                  console.log('this is my formdata', data)
                  
                  // let array = JSON.parse("[" + data.freePages + "]");
                  // console.log('this is my formdata array', array)
                  let noOfPages = parseInt(data.bookpages)
                  // let res = array.every((v)=>v<=noOfPages)
                  let res = noOfPages
                  // console.log(res)
                  if(res){
                      // let checkBox = same === false ? bookFileUrlMob : bookFileUrl
                      if(bookFileUrl && imageUrl ){

                        
                        let docRef=db.collection('zSystemStore').doc()
                        docRef.set({
                          title: data.booktitle,
                          author: data.author,
                          itemID: docRef.id,
      
                          type: 'book',
      
                          overview: data.bookoverview,
                          bookcover: imageUrl,
                          bookFileUrl: bookFileUrl,
                          // bookFileUrlMobile: checkBox,
                          aboutAuthor: data.aboutAuthor,
                          bookcode: data.bookcode,
                          bookpages: noOfPages,
                          // freePages: array,
                          // rating: parseInt(data.rating),
                          createdAt:firebase.firestore.FieldValue.serverTimestamp(),
                          lastUpdatedAt:firebase.firestore.FieldValue.serverTimestamp(),
                        }).then(function () {
                          notify('Book Added Successfully', false)
                          console.log('documnet successfully inserted')
                          e.target.reset();
                          setBookFileUrl()
                          setBookCover()
                          setimageUrl()
                          setBookImage()
                          setBookFileName()
                          history.push('/admin/book')
      
                          setAddFormVisible(true)
                        }).catch(function (error) {
                          notify(error.toString(), true)
      
                          console.log('this is error', error)
                        })
                    
                      }else{
                        notify('Please wait for the upload to Complete', true)
    
                      }
                    }else{
                      notify('Free page exceed the Total Pages', true)
  
                    }
                }
                )}
                >
                  <Row className='pr-md-1' md='1'>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Book Title</label>
                        <Input
                          innerRef={register({ required: true, minLength: 4 })}
                          name='booktitle'
                          placeholder="Title of Book"
                          type="text"
                          required
                        />

                        {errors.booktitle && errors.booktitle.type === 'minLength' && (
                          <span style={{ fontSize: '10px', position: 'absolute', bottom: '-20px', color: 'red' }}  >Title should be atleast 4 digits.</span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6" style={{}}>
                      <FormGroup>
                        <label>Author</label>
                        <Input
                          innerRef={register({ required: true, minLength: 4 })}
                          name='author'
                          placeholder="Name Of Author"
                          type="text"
                        />

                        {errors.author && errors.author.type === 'minLength' && (
                          <span style={{ fontSize: '10px', position: 'absolute', bottom: '-20px', color: 'red' }}  >Author should be atleast 4 digits.</span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Book Code</label>
                        <Input
                          defaultValue={1800}
                          innerRef={register({ required: true, })}
                          name='bookcode'
                          type='number'
                        />
                      </FormGroup>
                    </Col>
                    
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Number Of Pages</label>
                        <Input
                          innerRef={register({ required: true, min: 0 })}
                          name='bookpages'
                          placeholder="No. of Pages"
                          type='number'
                        />

                        {errors.bookpages && errors.bookpages.type === 'min' && (
                          <span style={{ fontSize: '10px', position: 'absolute', bottom: '-20px', color: 'red' }}  >Bookpages should be more than 0 .</span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Rating</label>
                        <Input
                          innerRef={register({ required: true, min: 0, max: 5 })}
                          name='rating'
                          placeholder="Rating Out of 5"
                          type='number'
                        />

                        {errors.rating && errors.rating.type === 'min' && (
                          <span style={{ fontSize: '10px', position: 'absolute', bottom: '-20px', color: 'red' }}  >Rating should be out of 5 .</span>
                        )}
                        
                        {errors.rating && errors.rating.type === 'max' && (
                          <span style={{ fontSize: '10px', position: 'absolute', bottom: '-20px', color: 'red' }}  >Rating should be out of 5 .</span>
                        )}
                      </FormGroup>
                    </Col>
                  
                    <Col className="pr-md-1" md="6">
                     <FormGroup>
                        <label>Free Pages</label>
                        <Input
                          innerRef={register({ required: true,
                            pattern: {
                                      value:  /^((\d+,)*(\d)+$)/,
                                      message: "Enter in vaild pattern"
                                      }
                             })}
                          name='freePages'
                          placeholder="Enter free pages in pattern 1,2,8,9 "
                          type='text'
                        />

                        {errors.freePages && errors.freePages.type === 'required' && (
                          <span style={{ fontSize: '10px', position: 'absolute', bottom: '-20px', color: 'red' }}  > Please Enter free pages .</span>
                        )}
                        
                        {errors.freePages && errors.freePages.type === 'pattern' && (
                          <span style={{ fontSize: '10px', position: 'absolute', bottom: '-20px', color: 'red' }}  >Enter in vaild pattern .</span>
                        )}
                      </FormGroup>
                     
                    </Col>

                  </Row> */}
                  <Row>
                    <Col className="pr-md-1" md="6" style={{  overflow: 'hidden' }}>
                      <FormGroup>
                        <label>Book Overview</label>
                        <Input
                          innerRef={register({ required: true, })}
                          name='bookoverview'
                          placeholder="Book Overview"
                          type='textarea'
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6" style={{  overflow: 'hidden' }}>
                      <FormGroup>
                        <label>About Author</label>
                        <Input
                          innerRef={register({ required: true, })}
                          name='aboutAuthor'
                          placeholder="About author"
                          type='textarea'
                        />
                      </FormGroup>
                    </Col>
                   

                  </Row>
                  <Row>
                  <Col className="pr-md-1" md="6" style={{ overflow: 'hidden' }}>
                      <Row>
                        <Col className='pr-md-1' md='4' style={{ maxWidth: '160px' }}>
                          {/* <Col className='pr-md-1' md='6'> */}
                          <FormGroup>
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop:2 }}>
                              <label>Upload Book for web  </label>
                              <label style={{ cursor: 'pointer', color: 'white', fontSize: 16 }}>{bookFileName === undefined ? <Button ><CloudUploadIcon /></Button> : <Button><CloudDoneIcon /></Button>}</label>
                            </div>
                            <Input
                              style={{ cursor: 'pointer' }}
                              innerRef={register({ required: true, accept: '.pdf' })}
                              name='bookfile'
                              placeholder='Upload Book'
                              onChange={selectBookFile}
                              type='file'
                              accept="application/pdf"

                              required
                            />
                            {bookFileName && <p style={{ color: 'white' }}>{bookFileName}</p>}

                          </FormGroup>


                          {/* </Col> */}
                          {/* <Col className='pr-md-1' md='6' style={{ paddingLeft: 10, marginTop: 20 }}>
                          </Col> */}
                        </Col>
                        {bookFileName &&
                          <Col md='6'>
                            <Button style={{ position: 'relative', top: '30px' }}
                              onClick={() => {
                                setClickedFile('web')
                                handleClickOpen()
                              }}
                            >Preview</Button>

                          </Col>

                        }
                      </Row>
                    </Col>
                    <Col md='6'>
                    <Row>
                        <Col className='pr-md-1' md='6'>

                          <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                            <label>Upload Book Image</label>
                            <label style={{ cursor: 'pointer', color: 'white', fontSize: 16 }}>{bookCover === undefined ? <Button ><CloudUploadIcon /></Button> : <Button><CloudDoneIcon /></Button>}</label>
                            <Input
                              style={{ cursor: 'pointer' }}
                              innerRef={register({ required: true, accept: '.jpeg,.png' })}
                              name='bookcover'
                              placeholder='Upload'
                              onChange={selectBookCover}
                              type='file'
                              accept='.jpeg ,.png'
                              required
                            />
                          </FormGroup>

                        </Col>
                        <Col className='pr-md-1' md='6' style={{ paddingLeft: 10 }}>
                          {bookImage && <img src={bookImage} alt="" style={{ width: 40, height: 55, marginTop: 15 }} />}
                        </Col>
                      </Row>
                   
                    </Col>
                    
                  </Row>
                  <Row>
                 <Col md='6'>
                      <Row>

                        {same === false &&
                          <>
                            {/* <Col className='pr-md-1' md='4'  style={{ maxWidth: '160px' }}> */}
                              {/* <Col className='pr-md-1' md='6'> */}
                              {/* <FormGroup>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label>Upload Book for Mobile </label>
                                  <label style={{ cursor: 'pointer', color: 'white', fontSize: 16 }}>{bookFileNameMob === undefined ? <Button ><CloudUploadIcon /></Button> : <Button><CloudDoneIcon /></Button>}</label>
                                </div>
                                <Input
                                  style={{ cursor: 'pointer' }}
                                  innerRef={register({ required: true, accept: '.pdf' })}
                                  name='bookfile'
                                  placeholder='Upload Book'
                                  onChange={selectBookFileMob}
                                  type='file'
                                  accept="application/pdf"

                                  required
                                />
                                {bookFileNameMob && <p style={{ color: 'white' }}>{bookFileNameMob}</p>}

                              </FormGroup>

                            </Col> */}
                            {
                              bookFileNameMob &&

                              <Col md='6'>
                                <Button style={{ position: 'relative', top: '30px' }}
                                  onClick={() => {

                                    setClickedFile('mob')
                                    handleClickOpen()
                                  }}
                                >Preview</Button>

                              </Col>
                            }
                          </>
                        }
                      </Row>
                    </Col>
                    
                  </Row>
                  {/* <Row style={{ paddingTop: '10px' }}>
                    <Col className="pr-md-1" md="6" >
                      <Col md={4}>
                        <Input id='bookCheck' type="checkbox"
                          onChange={(e) => {
                            setSame(e.target.checked)
                            console.log(e.target.checked)
                          }} />
                      </Col>
                      <Col md={6}>
                        <label for='bookCheck'>Use same pdf for mobile version</label>
                      </Col>
                    </Col>
                  </Row> */}
                  {/* <Row>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Free Pages</label>
                          <Input
                           innerRef={register({required:true,})}
                            name='freepages'
                            type='text'
                          />
                          </FormGroup>
                      </Col>
                      </Row> */}
                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>

                    <Button color='info' type='submit' style={{ marginRight: 5 }}>
                      Submit</Button>

                    <NavLink to='/admin/book' exact style={{ color: 'white' }}><Button>Back</Button></NavLink>

                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default Book_Add_Step1;
