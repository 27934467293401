import * as firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyCIHvxpgk5pgDtx9WzEBSFL9ZNAEqD6qBE",
  authDomain: "jumpstart-e023f.firebaseapp.com",
  projectId: "jumpstart-e023f",
  storageBucket: "jumpstart-e023f.appspot.com",
  messagingSenderId: "865808001288",
  appId: "1:865808001288:web:a1723bf68b530f6b9864bd",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
export { db };
