/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Modal,
  ModalBody,
  Progress,

  Dropdown, DropdownToggle, DropdownMenu, DropdownItem

} from "reactstrap";
import { Edit, Delete, Add } from '@material-ui/icons'
import GetAppIcon from '@material-ui/icons/GetApp';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import KeyboardArrowLeftSharpIcon from '@material-ui/icons/KeyboardArrowLeftSharp';
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';

import Add_Student from "./student_add_and_edit_form";
import { db } from '../MY_LOGIN_AND_SIGNUP/db/config'
import Edit_Student from "./Student_Edit._Form";
import Delete_modal from "./Delete_Modal";
import NotificationAlert from "react-notification-alert";
import StudentReport from './StudentReport';




function Students_Table() {
  const limit = 10;

  const [clicked, setClicked] = useState(false)


  const [progressVisible, setProgressVisible] = useState(true)
  const [progressValue, setProgressValue] = useState(8)
  const [userData, setUserData] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [editVisible, setEditVisible] = useState(false)
  const [editUser, setEditUser] = useState()
  const [deleteUserVisible, setDeleteUserVisible] = useState(false)
  const [deletedUser, setDeletedUser] = useState()
  const [currentReportId, setCurrentReportId] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);
  const [startIndex, setStartIndex] = useState(1)
  const [endIndex, setEndIndex] = useState(0)



  const toggle = () => setDropdownOpen(prevState => !prevState);

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const headers = [
    { label: "Enrollment No.", key: "enrollment" },
    { label: "First Name", key: "firstname" },
    { label: "Last Name", key: "lastname" },
    { label: "Email", key: "email" },
    { label: "Mobile No.", key: "mobile" },
    { label: "Status", key: "status" },
    { label: "Address", key: "address" },


  ];

  const notifi = useRef();
  const notify = (msg, err) => {
    // console.log('notify')
    let color = err == false ? 'success' : 'danger'
    let options = {
      type: color,
      place: "tr",
      message: (
        <div>
          <div>
            <b>{err == false ? 'Success' : 'Error'}</b> -{msg}.
          </div>
        </div>
      ),
      icon: "tim-icons icon-bell-55",
      autoDismiss: 6
    };
    if(notifi.current){
      notifi.current.notificationAlert(options)
    }
    // notification(options);
  }


  const getUsers = () => {
    setProgressValue(99)
    db.collection('zSystemStudents').orderBy('createdAt', 'desc').limit(limit)
      .onSnapshot(function (querySnapshot) {
        var students = []
        querySnapshot.forEach(function (doc) {
          students.push(doc.data())

        })
        console.log('student data', students)
        document.querySelector("#loading-indicator").style.display = "none";

        setUserData(students)
        setProgressVisible(false)
        setEndIndex(students.length)

        // var a = students.length
        // console.log(students[a - 1].enrollment)
        // setEnroll(students[a - 1].enrollment)
      })
    // .get()
    // .then(querySnapshot=>{
    //   let data = querySnapshot.docs.map(doc=>doc.data())

    //   setProgressVisible(false)
    //   setUserData(data)
    //   setEnroll(parseInt(data[data.length-1].enrollment ? data[data.length-1].enrollment :10000))
    // })
  }
  useEffect(() => {
    getUsers()
  }, [])


  const filterSearch = (data) => {
    console.log(data)
    document.querySelector("#loading-indicator").style.display = "flex";

    db.collection('zSystemStudents').where('status', '==', data).get()
      .then((querySnapshot) => {
        let data = querySnapshot.docs.map(doc => (doc.data()))
        // doc.data() is never undefined for query doc snapshots
        document.querySelector("#loading-indicator").style.display = "none";

        setUserData(data)

        console.log(" => ", data);

      }
      ).catch(err=>{
                                
        document.querySelector("#loading-indicator").style.display = "none";
        notify(err.toString(), true);
        console.log(err.toString())
      })

  }


  const nextSet = () => {
    // console.log(lastDoc)
    // if (userData.length === limit) {
      document.querySelector("#loading-indicator").style.display = "flex";

      db.collection('zSystemStudents').orderBy('createdAt', 'desc').startAfter(userData[userData.length-1].createdAt).limit(limit).get()
        .then(querySnapshot => {
          let data = querySnapshot.docs.map(doc => {

            return doc.data()
          })
          console.log('dataaaa', data)
          if (data.length !== 0) {
            document.querySelector("#loading-indicator").style.display = "none";

            setUserData(data)
            setStartIndex(endIndex + 1)
            setEndIndex(prev => prev + data.length)
            console.log('my user data is ', data)

          } else {
            document.querySelector("#loading-indicator").style.display = "none";

            notify('End Of the list', true)
          }

        }).catch(err=>{
                                
          document.querySelector("#loading-indicator").style.display = "none";
          notify(err.toString(), true);
          console.log(err.toString())
        })
    // } else {
    //   notify('End Of the list', true)
    // }
  }

  const prevSet = () => {
    document.querySelector("#loading-indicator").style.display = "flex";

    db.collection('zSystemStudents').orderBy('createdAt')
      .startAfter(userData[0].createdAt).limit(limit).get()
      // .endBefore(quizData[0].questionTitle).get()
      .then(querySnapshot => {
        let data = querySnapshot.docs.map(doc => {

          return doc.data()
        })
        if (data.length !== 0) {
          document.querySelector("#loading-indicator").style.display = "none";

          data = data.reverse()

          setStartIndex(prev => prev - data.length)
          setEndIndex(prev => prev - userData.length)
          setUserData(data)
          console.log('my user data is ', data)
        } else {
          document.querySelector("#loading-indicator").style.display = "none";

          console.log('Can not go back')
        }
      }).catch(err=>{
                                
        document.querySelector("#loading-indicator").style.display = "none";
        notify(err.toString(), true);
        console.log(err.toString())
      })
  }

  return (
    <>
      {progressVisible ? 
        <>
          <Progress color='info' value={progressValue} style={{ height: 4 }} />
          <div className="content">
          </div>
        </>  
        : <div className="content">
          <div className="react-notification-alert-container">
            <NotificationAlert ref={notifi} />
          </div>
          {/* <Row>
            <Col>
            <CSVLink
                data={data}
                asyncOnClick={true}
                onClick={(event, done) => {
                  new Promise(resolve => setTimeout(resolve, 5000)).then(() => {
                    done();
                  });
                }}
              >
                Download me after 5 sec?
              </CSVLink>
            </Col>
          </Row> */}
          <Row>
            <Col md="12">
              <Card>
                <CardHeader style={{ display: "flex", alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end' }}>
                  <CardTitle tag="h4" style={{ marginRight: 'auto', marginLeft: 'auto', fontWeight: 300 }}>STUDENT MANAGEMENT</CardTitle>
                  <Button color='info' style={{ marginRight: 25 }} onClick={() => setIsModalVisible(true)}>Create Student <Add /></Button>
                  <a href="https://us-central1-jumpstart-e023f.cloudfunctions.net/downloadStudentData">
                    <Button style={{ padding: '7px 13px' }} >
                        <GetAppIcon style={{ color: 'white' }} />
                    </Button>
                  </a>

                </CardHeader>
                <CardBody style={{ paddingTop: '10px' }}>
                  <Modal isOpen={reportOpen} style={{ display: 'flex', flex: 1, }} size='lg'>
                    <ModalBody style={{
                        width: '100%', display: 'inline-block', padding: '0px !important', position: 'absolute',
                        border: '1px solid white !important',
                      }} size='sm'>
                      <StudentReport handleCloseButton={() => setReportOpen(false)} currentReportId={currentReportId}/>
                    </ModalBody>
                  </Modal>
                  <Row style={{ justifyContent: 'flex-end' }}>

                    <Col style={{ display: 'flex', justifyContent: 'flex-end' }} sm={6}>
                      <p style={{ position: 'relative', top: '8px', marginRight: '15px' }}>
                        {startIndex <= 1 ? 1 : startIndex} - {endIndex}
                      </p>
                      <div style={{ position: 'relative', top: '-5px', marginRight: '20px' }}>

                      {
                          startIndex > 1 &&
                          <IconButton style={{ outline: 'none' }}
                            onClick={() => {

                              prevSet()
                            }}
                          >
                              <KeyboardArrowLeftSharpIcon style={{ color: 'white' }}
                                  
                              />
                          </IconButton>
                      }
                        <IconButton style={{ outline: 'none' }}
                          onClick={() => nextSet()}
                        >
                          <KeyboardArrowRightSharpIcon style={{ color: 'white' }} />

                        </IconButton>
                      </div>
                      <Dropdown isOpen={dropdownOpen} toggle={toggle} >
                        <DropdownToggle style={{ padding: '6px 12px' }} className="nav-link" color='info' caret>
                          <FilterListIcon />
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem style={{ color: 'black' }} header>Filter By Status</DropdownItem>
                          <DropdownItem style={{ color: 'black' }} onClick={() => {
                            document.querySelector("#loading-indicator").style.display = "flex";
                            
                            getUsers()}}>All</DropdownItem>
                          {/* <DropdownItem style={{ color: 'black' }} onClick={() => filterSearch('Onboarding')}>Onboarding</DropdownItem> */}
                          <DropdownItem style={{ color: 'black' }} onClick={() => filterSearch('Active')}>Active</DropdownItem>
                          <DropdownItem style={{ color: 'black' }} onClick={() => filterSearch('Inactive')}>Inactive</DropdownItem>
                          {/* <DropdownItem style={{ color: 'black' }} onClick={() => filterSearch('Payment pending')}>Payment pending</DropdownItem> */}
                          {/* <DropdownItem style={{ color: 'black' }} onClick={() => filterSearch('Limited access')}>Limited access</DropdownItem> */}
                          {/* <DropdownItem style={{ color: 'black' }} onClick={() => filterSearch('Archived')}>Archived</DropdownItem> */}

                        </DropdownMenu>
                      </Dropdown>
                    </Col>

                  </Row>
                  <Modal isOpen={isModalVisible} style={{ display: 'flex', flex: 1, }} size='lg'>
                    <ModalBody style={{
                      width: '100%', display: 'inline-block', padding: '0px !important', position: 'absolute',
                      border: '1px solid white !important',
                    }} size='sm'>
                      <Add_Student
                        notify={notify} 
                        setModal={(val)=>setIsModalVisible(val)}
                       ></Add_Student>
                    </ModalBody>

                  </Modal>
                  <Modal isOpen={deleteUserVisible} style={{ width: 250 }}>
                    <ModalBody style={{ width: '100%', padding: 0 }} size='sm'>
                      <Delete_modal
                        notify={notify}
                        setUserData={setUserData}
                        uid={deletedUser !== undefined && deletedUser._uniqueID}
                        onClick={() => {
                          setDeleteUserVisible(false)
                        }}
                      ></Delete_modal>
                    </ModalBody>

                  </Modal>
                  <Table className="tablesorter" >
                    <thead className="text-primary">
                      <tr>
                        <th>Full Name</th>
                        {/* <th>Enrollment-No.</th> */}
                        <th>Mobile</th>
                        <th>Created From</th>

                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userData.map(user => (
                        <tr key={user._uniqueID} >
                          <td>{user.firstname + ' ' + user.lastname}</td>
                          {/* <td>{user.enrollment}</td> */}
                          <td>{user.mobile}</td>
                          <td>{user.createdFrom && user.createdFrom}</td>

                          <td>{user.status}</td>
                          <td><Edit onClick={() => {
                            setEditVisible(true)
                            console.log('my edit data', user)
                            setEditUser(user)
                            console.log("my edit state data", editUser)
                          }} style={{color:'#247cf7', cursor: "pointer"}} />
                            <Modal isOpen={editVisible} style={{ display: 'flex', flex: 1, top: 0 }} size='lg'>
                              <ModalBody style={{
                                width: '100%', display: 'inline-block', padding: '0px !important', position: 'absolute',
                                border: '1px solid white !important',
                              }} size='sm'>
                                <Edit_Student
                                  notify={notify}
                                  userData={editUser}
                                  setUserData={setUserData}
                                  // firstname={editUser!==undefined && editUser.firstname} 
                                  // lastname={editUser!==undefined&& editUser.lastname}
                                  // mobile={editUser!==undefined &&editUser.mobile}
                                  // status={editUser!==undefined &&editUser.status}
                                  // enrollment={editUser!==undefined && editUser.enrollment}
                                  onClick={() => {
                                    setEditVisible(false)
                                  }}
                                >
                                </Edit_Student>
                              </ModalBody>

                            </Modal>
                            <Delete style={{ color: 'red', cursor: "pointer"}} notify={notify} onClick={() => {
                              setDeleteUserVisible(true)
                              setDeletedUser(user)
                            }} />
                          
                            <Button color= "info" style={{marginLeft: 10, padding: "10px 20px"}} onClick={() =>  {
                              setCurrentReportId(user._uniqueID)
                              setReportOpen(true);
                              }}>
                              Report
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>}
    </>
  );
}


export default Students_Table;
