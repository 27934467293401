import { Add } from "@material-ui/icons";
import React,{useState} from "react";
import { NavLink} from 'react-router-dom'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Row,
  Col,
  Progress,
  CardTitle
} from "reactstrap";

import BookList from "./Book_List";
 //import {db} from '../MY_LOGIN_AND_SIGNUP/db/config'

function Book_Add_Page(){
    const [visible,setVisible] = useState(false)
    const [progressVisible,setProgressVisible] = useState(true)
    const [progressValue,setProgressValue] = useState(5)
    
    return (
      <>
      {progressVisible && <Progress color='info' value={progressValue} style={{height:4}}/>}
        <div className="content" style={{objectFit:'fill',overflow:'hidden'}}>
           {/* <Row style={{}}>
            <Col md="8">
              <Card style={{}}>
                <CardHeader>
                  <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
                  <h4 className="title" style={{marginRight:300,fontWeight:450}}>BOOK MANAGEMENT</h4>
                  <Button color='info' onClick={()=>{

                  }}><NavLink to='/admin/book-step-1' style={{color:'white'}}> Add Book </NavLink></Button>
                 </div>
                </CardHeader>
                <CardBody style={{}}>
                </CardBody>
               </Card>
            </Col>
           </Row>  */}
           <Row style={{objectFit:'fill',overflow:'hidden'}}>
             <Col md="12">
             <div>
            <Card style={{}}>
              <Row style={{ alignItems:'center' }}>
              <CardTitle tag="h4" style={{ marginRight: 'auto', marginLeft: 'auto', fontWeight: 300, marginBottom: 0, color:'#fff' }}>BOOK MANAGEMENT</CardTitle>
                { visible ===false &&
                  <div style={{display:'flex',justifyContent:'flex-end',marginRight:25,marginTop:13}}>
                    <NavLink to={{
                      pathname:'/admin/book-step-1',
                      setVisible:setVisible
                    }} exact style={{color:'white'}}> 
                      <Button color='info'>Add Book <Add /></Button> 
                    </NavLink>
                  </div> 
                }
              </Row>

            
              { visible === false && <CardHeader></CardHeader>}
               <CardBody style={{paddingTop:'0px'}}> 
                 <Col>
                 <Row>
                  <BookList setProgressVisible={setProgressVisible} setVisible={setVisible} 
                    setProgressValue={setProgressValue} >
                  </BookList>

                 </Row>
                 </Col>
               </CardBody> 
               {visible === false && <CardFooter></CardFooter>}
              </Card>
              
              
        </div>
             </Col>
           </Row>
        </div>
      </>
    );
  }
export default Book_Add_Page;