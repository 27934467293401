import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table,
} from "reactstrap";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

function TopicAnalyticsDetailDialog({ handleClose, element = [], userData, topics }) {

console.log("Topics123",element)
  return (
    <>
      <div
        style={{
          backgroundColor: "#1f1e2e",
          height: "100%",
          overflowY: "scroll",
        }}
      >
        <div style={{ padding: "5px 20px" }}>
          <Row style={{ justifyContent: "flex-end" }}>
            <IconButton
              edge="start"
              style={{ color: "white" }}
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Row>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col sm={12}>
                      <CardTitle tag="h4" style={{ fontWeight: 300 }}>
                        {element[0]?._model.label}
                      </CardTitle>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <Table className="tablesorter">
                    <thead className="text-primary">
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Time Spend</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userData.map((user) => (
                        user.timeSpent[topics[element[0]?._index]?.itemID] &&
                        <tr>
                          <td>{`${user.firstname} ${user.lastname}`}</td>
                          <td>{user.email}</td>
                          <td>{user.timeSpent[topics[element[0]?._index]?.itemID]} mins</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default TopicAnalyticsDetailDialog;
