import * as firebase from 'firebase'
import React,{useEffect} from 'react'
import {useHistory} from 'react-router-dom'



const GetUser = () => {
    
const history = useHistory()

useEffect(() => {
    
    firebase.auth().onAuthStateChanged((user) => {
        // console.log(user.uid);
            
        if (user) {
            // User is signed in.
            console.log(user);
            const uid = user.uid;
            localStorage.setItem("uid", uid);
            history.push(`/admin`)
            // db.collection('zSystemUsers').doc(uid)
            // .get()
            // .then(querySnapshot => {
            //     let data = querySnapshot.data()
                

            //     // let currentPermission = pathOptions.filter((item)=> data.permissions.find(v => v.value == item.value));
            //     // console.log('currentPermission',currentPermission)
            //     // if(currentPermission.length !== 0){

            //     // }
            //     console.log('current User login',user)
            // })

        }
        else{
            console.log('user not login')
            history.push('/login')
            localStorage.removeItem("uid");
        }
    })
   
}, [])

    return(
        <div></div>
    )

} 
export default GetUser




