import React, { useState, useEffect, useRef } from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Row,
    Table,
    Col,
    Progress,
} from "reactstrap";
import { useForm } from "react-hook-form";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import NotificationAlert from "react-notification-alert";
import firebase from 'firebase';
import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";

function PackageDialog({ handleClose,notifi, setPackageData, currentPackage }) {



    const notification = useRef();

    const { handleSubmit, register, errors } = useForm();
    const [bookData, setBookData] = useState([])
    const [quizData, setQuizData] = useState([])
    const [selectedBook, setSelectedBook] = useState({})
    const [selectedQuiz, setSelectedQuiz] = useState({})
    const [selectedQuizCount, setSelectedQuizCount] = useState([])
    const [selectedBookCount, setSelectedBookCount] = useState([])
    const [noOfQuestions, setNoOfQuestions] = useState('')
    const [progressVisible, setProgressVisible] = useState(true)
    const [progressValue, setProgressValue] = useState(8)
    const [topicCover, setTopicCover] = useState();
    const [topicImage, setTopicImage] = useState();
    const [imageUrl, setimageUrl] = useState('');

    
    // useEffect(()=>{
    //     console.log('topic data',bookData)
    // },[bookData])

    
    // useEffect(()=>{
    //     console.log('topic selectedBook',selectedBook)
    // },[selectedBook])
    // console.log('selectedBook',selectedBook)
    // console.log('selectedQuiz',selectedQuiz)

    const notify = (msg, err) => {
        let color = err === true ? 'danger' : 'success'
        let options = {
            type: color,
            place: "tr",
            message: (
                <div>
                    <div>
                        <b>{err === true ? 'Error' : 'Success'}</b> -{msg}.
              </div>
                </div>
            ),
            icon: "tim-icons icon-bell-55",
            autoDismiss: 6
        };
        if(notifi.current){
            notification.current.notificationAlert(options)
        }
    }
    let bookCount = Object.entries(selectedBook).map(([k, v], i) => {
        //    let a= {item[i],item[i+1}

        // console.log(k,v,i)
        return { id: k, value: v }
    }
    )
    let quizCount = Object.entries(selectedQuiz).map(([k, v], i) => {
        //    let a= {item[i],item[i+1}

        // console.log(k,v,i)
        return { id: k, value: v }
    }
    )

    useEffect(() => {
        bookCount = bookCount.filter((item) => (item.value === true))
        setSelectedBookCount(bookCount)

        // console.log('selected COunt', bookCount)
    }, [selectedBook])

    useEffect(() => {
        quizCount = quizCount.filter((item) => (item.value === true))
        setSelectedQuizCount(quizCount)

        // console.log('selected COunt', quizCount)
    }, [selectedQuiz])

    const getBookData = (needfilter) => {

        db.collection('zSystemStore').where('type', '==', 'book').get().then(querySnapshot => {
            let book = querySnapshot.docs.map((doc) => doc.data())
            // console.log('All data',data)
            // let book =data.filter(item=>item.type==='book')
            // let quiz =data.filter(item=>item.type==='quiz')
            if (needfilter) {
                console.log('inside edit get book', needfilter)

            }
            setBookData(book)

            // setQuizData(quiz)
        })
    }

    const selectTopicCover = (event) => {
        console.log(event.target.files[0])
        console.log("my image name", event.target.files[0].name)
        if (!event.target.files[0]) {
          return
        }
        if(event.target.files[0].type=== 'image/jpeg' ||event.target.files[0].type=== 'image/png' ){
    
          try {
            let date = new Date().valueOf();
    
            var uploadTask = firebase.storage().ref().child(`topicCover/${date}${event.target.files[0].name}`).put(event.target.files[0])
            uploadTask.on('state_changed', function (snapshot) {
              var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('upload is ' + progress + '% done');
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED:
                  console.log('upload is paused')
                  break;
                case firebase.storage.TaskState.RUNNING:
                  console.log('upload is running')
                  break
              }
            }, function (error) {
              console.log('this is error', error)
            }, function () {
              uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                notify('Book Cover Uploaded Successfully', false)
      
                console.log('File available at ', downloadURL)
                setimageUrl(downloadURL)
                setTopicImage(downloadURL)
              }).catch(err => {
                notify(err.toString(), true)
      
              })
            })
      
          } catch (error) {
            notify(error.toString(), true)
      
            console.log('my error', error)
          }
          setTopicCover(event.target.files[0].name)
        }else {
          notify('Please select only .jpeg/.png file', true)
    
        }
    }

    const getQuizData = (needfilter) => {

        db.collection('zSystemStore').where('type', '==', 'quiz').get().then(querySnapshot => {
            let quiz = querySnapshot.docs.map((doc) => doc.data())
            // console.log('All data',data)
            // let book =data.filter(item=>item.type==='book')
            // let quiz =data.filter(item=>item.type==='quiz')
            if (needfilter) {
                console.log('inside edit get quiz', needfilter)

            }
            setProgressVisible(false)

            // setBookData(book)
            setQuizData(quiz)
        })
    }

    const getBooks = () => {
        // let currentbookArray = currentPackage.book
        console.log('in get EDit Package book')
        db.collection('zSystemStore').where('type', '==', 'book')
            // .where('__name__', 'in', currentbookArray)
            .get()
            .then(querySnapshot => {
                let data = querySnapshot.docs.map(doc => {

                    return { ...doc.data() }
                })
                let bookArray = {};
                currentPackage.book.forEach((item, i) => {
                    console.log(item)
                    bookArray[item] = true
                })

                console.log('current bookArray Array', bookArray)
                setSelectedBook(bookArray)
                // setQuestionsData(data)
                // setEndIndex(data.length)
                setBookData(data)
                // getBookData(data)
                console.log('my user data is ', data)
            }).catch(err => {
                notify(err.toString(), true)

                console.log('err', err)

            })
    }

    const getQuiz = () => {
        console.log('in get EDit Package book')
        db.collection('zSystemStore').where('type', '==', 'quiz')
            // .where('__name__', 'in', currentQuizArray)
            .get()
            .then(querySnapshot => {
                let data = querySnapshot.docs.map(doc => {

                    return { ...doc.data() }
                })
                console.log('current quiz data data', data)

                let quizArray = {};
                currentPackage.quiz.forEach((item, i) => {
                    console.log(item)
                    quizArray[item] = true
                })
                setProgressVisible(false)

                console.log('current quizArray Array', quizArray)
                setSelectedQuiz(quizArray)
                // setQuestionsData(data)
                // setEndIndex(data.length)
                setQuizData(data)
                // getQuizData()
                console.log('my user data is ', data)
            }).catch(err => {
                notify(err.toString(), true)

                console.log('err', err)

            })
    }

    useEffect(() => {
        setProgressValue(99)
  
        console.log('currentPackage', currentPackage)
        if (currentPackage !== '') {
            // if (currentPackage.numberOfBook !== 0) {

                getBooks()

            // }
            // if (currentPackage.numberOfQuiz !== 0) {

                getQuiz()

            // }

        } else {
            getBookData()
            getQuizData()
        }

    }, [])

    const onSubmit = (data) => {
        // console.log('onsubmit', data)
        // console.log('bookCount', bookCount)
        console.log('quizCount', quizCount)

        let finalSelectedQuiz = quizCount.lenght !== 0 ? quizCount.map(item => item.id) : []
        let finalSelectedBook = bookCount.lenght !== 0 ? bookCount.map(item => item.id) : []
        console.log('finalSelectedQuiz', finalSelectedQuiz)
        console.log('finalSelectedBook', finalSelectedBook)

        let questions = noOfQuestions === '' ? 0 : noOfQuestions
        let res;
        if (currentPackage) {
            res = db.collection('zSystemStore').doc(currentPackage.itemID)
        } else {
            res = db.collection('zSystemStore').doc()
        }
        
        let finalData = {}

        if (currentPackage) {
            console.log("object")
            finalData = {
                itemID: res.id,
                type: 'topic',
                title: data.packageTitle,
                quiz: finalSelectedQuiz,
                topicCover: imageUrl,
                numberOfQuiz: quizCount.length,
                book: finalSelectedBook,
                numberOfBook: bookCount.length,
                numberOfQuestions: questions,
                createdAt:firebase.firestore.FieldValue.serverTimestamp(),
                lastUpdatedAt:firebase.firestore.FieldValue.serverTimestamp(),
            }
        } else {
            finalData = {
                itemID: res.id,
                type: 'topic',
                title: data.packageTitle,
                quiz: finalSelectedQuiz,
                topicCover: imageUrl,
                numberOfQuiz: quizCount.length,
                book: finalSelectedBook,
                numberOfBook: bookCount.length,
                numberOfQuestions: questions,
                lastUpdatedAt:firebase.firestore.FieldValue.serverTimestamp(),
            }
        }
         
        res.set(finalData).then(res => {
            if (currentPackage) {

            notifi('Topic updated Successfully', false)
        } else {

            notify('Topic has been submitted', false)
        }
            if (currentPackage) {

                setPackageData(prev => prev.map((item) => item.itemID == currentPackage.itemID ? ({ ...finalData }) : item))
            
            } else {

                setPackageData(prev => [...prev, finalData])

            }

            handleClose()
            console.log('submitted', res)
        }).catch(err => {
            notify(err.toString(), true)
            notifi(err.toString(), true)


        })
        console.log('response', res)
    }

    return (
        <div style={{ backgroundColor: '#1f1e2e', height: '100%', overflowY: 'scroll' }} >
            {progressVisible && <Progress color='info' value={progressValue} style={{ height: 4 }} />}

            <div className="react-notification-alert-container">
                <NotificationAlert ref={notification} />
            </div>

            <div style={{ padding: '5px 20px' }}>
                <Row style={{ justifyContent: 'flex-end' }}>
                    <IconButton edge="start" style={{ color: 'white' }} onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>

                </Row>

                <Row >
                    <Col md="12" style={{ marginBottom: '100px' }}>
                        <Card style={{ margin: 0 }}>
                            <CardHeader>
                                <h5 className="title">Topic Management</h5>
                            </CardHeader>
                            <CardBody>

                                <Row>
                                    <Col md="6" style={{ borderRight: '1px solid #3d4356' }}>
                                        <h5 className="title" style={{ paddingLeft: '20px' }}>Books</h5>
                                        <br></br>

                                        <Table className="tablesorter" >
                                            <thead className="text-primary">
                                                <tr>
                                                    <th> </th>
                                                    <th>Book Tile</th>
                                                    <th>Author</th>
                                                    {/* <th>Price</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    bookData.map((item) => {

                                                        return (
                                                            <tr key={item.itemID} >
                                                                <td style={{ display: 'flex', paddingLeft: '20px' }} >
                                                                    {() => {
                                                                        console.log(selectedBook[item.itemID]);
                                                                        return null;
                                                                    }}
                                                                    <Input type="checkbox" checked={selectedBook[item.itemID] || false} onClick={(e) => {
                                                                        setSelectedBook((prev) => ({ [item.itemID]: !prev[item.itemID] }))
                                                                    }} />
                                                                </td>
                                                                <td>{item.title}</td>
                                                                <td>{item.author}</td>
                                                                {/* <td style={{ minWidth: '70px' }}>₹ {item.price}</td> */}
                                                            </tr>

                                                        )
                                                    })
                                                }

                                            </tbody>

                                        </Table>
                                    </Col>
                                    <Col md={6} style={{ borderRight: '1px solid #3d4356' }}>
                                        <h5 className="title" style={{ paddingLeft: '20px' }}>Quiz</h5>
                                        <br></br>

                                        <Table className="tablesorter" >
                                            <thead className="text-primary">
                                                <tr>
                                                    <th> </th>
                                                    <th>Quiz Tile</th>
                                                    <th>No of Questions</th>
                                                    {/* <th>Price</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    quizData.map((item) => {

                                                        return (
                                                            <tr key={item.itemID}>
                                                                <td style={{ display: 'flex', paddingLeft: '20px' }} >
                                                                    <Input type="checkbox" checked={selectedQuiz[item.itemID] || false} onChange={(e) => {
                                                                        setSelectedQuiz((prev) => ({ [item.itemID]: !prev[item.itemID] }))
                                                                    }} /></td>
                                                                <td>{item.title}</td>
                                                                <td>{item.numberOfQuestions}</td>
                                                                {/* <td style={{ minWidth: '70px' }}>₹ {item.price}</td> */}
                                                            </tr>

                                                        )
                                                    })
                                                }

                                            </tbody>

                                        </Table>
                                    </Col>

                                    {/* <Col sm={3} >
                                        <h5 className="title">No of Questions</h5>
                                        <br></br>

                                        <Input defaultValue={currentPackage && currentPackage.numberOfQuestions} type='number' placeholder="No Of Questions" onChange={(e) => setNoOfQuestions(e.target.value)} />
                                    </Col> */}
                                </Row>

                                <br></br>
                                {/* <Table className="tablesorter" >
                                    <thead className="text-primary">
                                        <tr>
                                            <th> </th>
                                            <th>Question Tile</th>
                                            <th>Subject</th>
                                            <th>Difficulty Level</th>
                                        </tr>
                                    </thead>
                                    <tbody> */}
                                {/* {
                                            questionsData.map((item) => {

                                                return (
                                                    <tr key={item.id}>
                                                        <td style={{ display: 'flex', paddingLeft: '20px' }} >
                                                            <Input type="checkbox" checked={selectedQues[item.id]} onChange={(e) => {
                                                                setSelectedQues((prev) => ({ ...prev, [item.id]: !prev[item.id] }))
                                                            }} /></td>
                                                        <td>{item.questionTitle}</td>
                                                        <td>{item.subject}</td>
                                                        <td>{item.difficulty}</td>
                                                    </tr>

                                                )
                                            })
                                        } */}

                                {/* </tbody>

                                </Table> */}

                            </CardBody>
                        </Card>
                    </Col>

                </Row>
                {selectedBookCount.length !== 0 && selectedQuizCount.length !== 0
                    ?
                    <Row style={{ position: 'fixed', bottom: '0', minHeight: '60px', display: 'flex', width: '100%', justifyContent: 'flex-end', left: '-10', backgroundColor: '#272a3d' }}>
                        <Form style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
                            onSubmit={handleSubmit(onSubmit)}
                        >

                            <Row style={{ width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Col sm={9}>
                                    <Row style={{ alignItems: 'center' }}>
                                        <Col sm={1} style={{ textAlign: 'end', minWidth: '125px' }}>
                                            <label style={{ color: 'white' }}>Topic Title</label>
                                        </Col>
                                        <Col sm={4}>
                                            <FormGroup>

                                                <Input
                                                    defaultValue={currentPackage && currentPackage.title}
                                                    innerRef={register({ required: true })}
                                                    name='packageTitle'
                                                    placeholder="Enter Topic Title"
                                                    type="text"
                                                // required
                                                />
                                                {errors.packageTitle && errors.packageTitle.type === "required" && notify("Package Title field can't be empty", true)}

                                            </FormGroup>

                                        </Col>
                                            <Col sm={2}>
                                                <label style={{minWidth: 175, color: 'white'}}>Upload Topic Image</label>
                                            </Col>
                                            <Col sm={4} style={{display: 'flex'}}>
                                                <FormGroup style={{ display: 'flex' }}>
                                                    <label style={{ cursor: 'pointer', color: 'white', fontSize: 16 }}>{topicCover === undefined ? <Button ><CloudUploadIcon /></Button> : <Button><CloudDoneIcon /></Button>}</label>
                                                    <Input
                                                    style={{ cursor: 'pointer' }}
                                                    innerRef={register({ required: true, accept: '.jpeg,.png' })}
                                                    name='bookcover'
                                                    placeholder='Upload'
                                                    onChange={selectTopicCover}
                                                    type='file'
                                                    accept='.jpeg ,.png'
                                                    required
                                                    />
                                                </FormGroup>
                                                {topicImage && <img src={topicImage} alt="" style={{ width: 40, height: 55, marginTop: 5, marginLeft:20 }} />}
                                            </Col>


                                        {/* <Col sm={2} style={{ textAlign: 'end', maxWidth: '80px' }}>
                                            <label style={{ color: 'white' }}>Price</label>
                                        </Col>
                                        <Col sm={2}>
                                            <FormGroup>

                                                <Input
                                                    defaultValue={currentPackage && currentPackage.price}
                                                    innerRef={register({ required: true })}
                                                    name='price'
                                                    placeholder="Price"
                                                    type="number"
                                                // required
                                                />
                                                {errors.price && errors.price.type === "required" && notify("Price field can't be empty", true)}

                                            </FormGroup>

                                        </Col> */}
                                    </Row>
                                </Col>
                                {/* <Col sm={3}></Col> */}
                                <Col sm={2} style={{ marginLeft: '20px' }}>
                                    <Button color='info' type='submit'>Submit</Button>

                                </Col>
                            </Row>
                        </Form>

                    </Row>
                    :
                    <div></div>
                }
            </div>
        </div >
    )
}
export default PackageDialog