import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import {useForm} from 'react-hook-form'
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import * as firebase from 'firebase'
import {Input,FormGroup,Form,} from 'reactstrap'


const useStyles = makeStyles((theme) => ({
  body: {
    width: "50%",
    height: "100%",
    minHeight: "100vh",
  },
  root: {
    marginLeft: theme.spacing(5),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn(props) {

  
  const classes = useStyles();
  const {register,handleSubmit} = useForm()  
  const [emailError,setEmailError] = useState('')
  const [passwordError,setPasswordError] = useState('')

   
    return (
      
      
      <div style={{ overflow:'hidden',height:window.innerHeight,width:window.innerWidth,background: `url(${'https://crimsonbeans-my.sharepoint.com/personal/honey_yadav_crimsonbeans_co_in/Documents/Microsoft%20Teams%20Chat%20Files/back.jpg'})`}}>
        <Container  className={classes.root} component='main' maxWidth='xs'>
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar> 
          <Typography component="h1" variant="h2" style={{color:'black'}}>
            Sign in
          </Typography>
          <Form className={classes.form} noValidate onSubmit={handleSubmit(data=> {
            console.log("hnadle submit executed")
             let email = data.email
             let password = data.password
             if(email.indexOf('@')===-1||email.indexOf('.')===-1){
               setEmailError('')
               setPasswordError("Email is not in valid format")
               return
             }
             
              else{
                   setPasswordError('')
                firebase.auth().signInWithEmailAndPassword(email, password).then(function(user) {
                  console.log(props)
                   props.history.replace('/admin')
                  console.log("user is sign inned")
                  console.log(user)
                  // db.collection('zSystemUsers').doc(user.user.uid)
                  //   .get()
                  //   .then(querySnapshot => {
                  //     let data = querySnapshot.data()

                  //     let currentPermission = pathOptions.filter((item)=> data.permissions.find(v => v.value == item.value));
                  //     console.log('currentPermission',currentPermission)
                  //     if(currentPermission.length !== 0){

                  //        props.history.replace(`/admin${currentPermission[0].path}`)
                  //     }
                  //     console.log("user is sign inned")
                  //   }).catch(err=>console.log(err))
                }).catch(function(error){
                 setEmailError('Wrong email or password')
                 console.log(error.code);
                 console.log(error.message);
                 
               });
              }                           
          })}>
  
        
        <Grid container style={{display:'flex',justifyContent:'center',alignContent:'center'}}>
              <Grid item xs>
               
                {emailError!=='' && <p style={{color:"#ff0066",textAlign:'center',marginTop:20}}>{emailError}</p> }
              </Grid>
              
            </Grid>
            <FormGroup style={{marginBottom:35,marginTop:emailError===""?25:5}}>
            <Input  style={{marginTop:20,color:'black'}}
                   name='email'
                   placeholder="Email"
                  type='email'
                  innerRef={register({required:true,})} 
                  
                  />
                  {passwordError!=="" && <p style={{color:'#ff0066',textAlign:'center'}}>{passwordError}</p>}
                  {/* {emailError!==" "&&<p style={{color:'red'}}>{emailError}</p>} */}
                  </FormGroup>
            <FormGroup>
            <Input style={{marginTop:50,color:'black'}} name="password"
            placeholder="Password"
            type='password'
            innerRef={register({required:true,})}
            />
            {/* {passwordError!=="" && <p style={{color:'red'}}>{passwordError}</p>} */}
            </FormGroup>
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
          </Form>
        </div>
        
      </Container>
      </div>
    )
      
   }
