import React, { useState, useEffect, useRef } from 'react'
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Row,
    Table,
    Col,
    Progress,
} from "reactstrap";
import Switch from '@material-ui/core/Switch'
import { useForm } from "react-hook-form";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import NotificationAlert from "react-notification-alert";

import firebase from 'firebase'

import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";


function QuizDialog({currentQuiz,startIndex, setQuizesData, handleClose, notifi }) {

    const notification = useRef();
    const [questionsData, setQuestionsData] = useState([])
    const [selectedQues, setSelectedQues] = useState({})
    const [selectedQuesCount, setSelectedQuesCount] = useState([])

    const [progressVisible, setProgressVisible] = useState(true)
    const [progressValue, setProgressValue] = useState(8)
    const [iswithoutTime, setIswithoutTime]=useState(false)
    const { handleSubmit, register, errors } = useForm();

    const notify = (msg,err) => {
        let color = err === true ?'danger':'success'
        let options = {
            type: color,
            place: "tr",
            message: (
                <div>
                    <div>
                        <b>{err === true ?'Error':'Success'}</b> -{msg}.
              </div>
                </div>
            ),
            icon: "tim-icons icon-bell-55",
            autoDismiss: 6
        };
        if(notifi.current){
            notification.current.notificationAlert(options)
        }
    }

    const getQuestions = () => {

        db.collection('Questions').orderBy('questionTitle')
            .get()
            .then(querySnapshot => {
                let data = querySnapshot.docs.map(doc => {

                    return { ...doc.data(), id: doc.id }
                })
                setProgressVisible(false)
                document.querySelector("#loading-indicator").style.display = "none";
                setQuestionsData(data)
            }).catch(err=>{
                document.querySelector("#loading-indicator").style.display = "none";

                console.log('err',err)
                notify(err.toString(),true)

            })
    }


    const getEditQuestions=()=>{
        db.collection('Questions').orderBy('questionTitle') 
            .get()
            .then(querySnapshot => {
                let data = querySnapshot.docs.map(doc => {

                    return { ...doc.data(), id: doc.id }
                })
                let currentQuesArray= {};
                currentQuiz.questions.forEach((item, i) => {
                    currentQuesArray[item] = true
                })

                setProgressVisible(false)
                document.querySelector("#loading-indicator").style.display = "none";

                setSelectedQues(currentQuesArray)
                setQuestionsData(data)
                // setEndIndex(data.length)
            }).catch(err=>{
                document.querySelector("#loading-indicator").style.display = "none";
                notify(err.toString(),true)
                
                console.log('err',err)

            })
    }

    useEffect(()=>{
        setProgressValue(99)
        if(currentQuiz.questions){
            if(currentQuiz.questions.length > 10){
                let currentQuesArray= {};
                currentQuiz.questions.forEach((item, i) => {
                    currentQuesArray[item] = true
                })
                setSelectedQues(currentQuesArray)
            }
        }
    },[questionsData])

    useEffect(() => {
        if(currentQuiz){
            getEditQuestions()  
        }else{
            getQuestions()
        }
    }, [])

    let count = Object.entries(selectedQues).map(([k, v], i) => {
        return { id: k, value: v }
    })

    useEffect(() => {
        count = count.filter((item) => (item.value === true))
        setSelectedQuesCount(count)
    }, [selectedQues])

    const submitQuiz = async(data) => {
        
        if (selectedQuesCount.length !== 0) {
            let finalSelectedQuestion = selectedQuesCount.map(item=>item.id)
            let res;
            if(currentQuiz){
                res=db.collection('zSystemStore').doc(currentQuiz.id)
            }else{
                res=db.collection('zSystemStore').doc()
            }
            let finalData = {}
            let time =iswithoutTime ? 10: parseInt(data.time)
            if(currentQuiz){
                finalData = {
                    itemID:res.id,
                    title: data.quizTitle,
                    questions: finalSelectedQuestion,
                    numberOfQuestions: selectedQuesCount.length,
                    timePerQues: time,
                    rating:parseInt(data.rating),
                    type: 'quiz',
                    withoutTimer:iswithoutTime,
                    lastUpdatedAt:firebase.firestore.FieldValue.serverTimestamp(),
                }
            }else{
                finalData = {
                    itemID:res.id,
                    title: data.quizTitle,
                    questions: finalSelectedQuestion,
                    numberOfQuestions: selectedQuesCount.length,
                    timePerQues: time,
                    rating:parseInt(data.rating),
                    type: 'quiz',
                    withoutTimer:iswithoutTime,
                    createdAt:firebase.firestore.FieldValue.serverTimestamp(),
                    lastUpdatedAt:firebase.firestore.FieldValue.serverTimestamp(),
                }
            }

            res.set(finalData).then(() => {
                notify('Quiz created Succesfully ',false)
                notifi('Quiz created Succesfully ')
                if(currentQuiz){

                    setQuizesData(prev=> prev.map((item) => item.id == currentQuiz.id ? ({ ...finalData}) : item))
                
                }else if(startIndex <= 1){

                    setQuizesData(prev => ([{ ...finalData, itemID: res.id ,id:res.id}, ...prev]))
                }

                handleClose()
                // })

            }).catch((err) =>{
                notify(err.toString(),true)

                console.log('err', err)
            } 
            )
        } else {
            console.log('in else')
        }

    }

    return (
        
        <div style={{ backgroundColor: '#1f1e2e', height: '100%', overflowY: 'scroll' }} >
            {progressVisible && <Progress color='info' value={progressValue} style={{ height: 4 }} />}
            <div className="react-notification-alert-container">
                <NotificationAlert ref={notification} />
            </div>

            <div style={{ padding: '5px 20px' }}>
                <Row style={{ justifyContent: 'flex-end' }}>
                    <IconButton edge="start" style={{ color: 'white' }} onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>

                </Row>

                <Row >
                    <Col md="12" style={{marginBottom:'100px'}}>
                        <Card style={{ margin: 0 }}>
                            <CardHeader>
                                <h5 className="title">Quiz Management</h5>
                            </CardHeader>
                            <CardBody>
                                <br></br>
                                <Table>
                                    <thead className="text-primary">
                                        <tr>
                                            <th> </th>
                                            <th>Question Tile</th>
                                            <th>Subject</th>
                                            <th style={{textAlign:'center',minWidth:'130px'}}>Difficulty Level</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            questionsData.map((item) => {

                                                return (
                                                    <tr key={item.id}>
                                                        <td style={{ display: 'flex', paddingLeft: '20px' }} >
                                                            <Input type="checkbox" checked={selectedQues[item.id]} onChange={(e) => {
                                                                setSelectedQues((prev) => ({ ...prev, [item.id]: !prev[item.id] }))
                                                            }} /></td>
                                                        <td>{item.questionTitle}</td>
                                                        <td>{item.subject}</td>
                                                        <td style={{textAlign:'center'}}>{item.difficulty}</td>
                                                    </tr>

                                                )
                                            })
                                        }

                                    </tbody>

                                </Table>

                            </CardBody>
                        </Card>
                    </Col>

                </Row>
            </div>
            {selectedQuesCount.length !== 0
                &&
                <Row style={{ position: 'fixed', bottom: '0',marginLeft:'0px',marginRight:'0px', minHeight: '60px', display: 'flex', width: '100%', justifyContent: 'flex-end', left: '-10', backgroundColor: '#272a3d' }}>
                    <Form style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onSubmit={handleSubmit(submitQuiz)}>

                        <Row style={{ width: '100%', alignItems: 'center' }}>

                            <Col sm={1}>
                                <label style={{ color: 'white', maxWidth:'200px' }}>{selectedQuesCount.length} Selected</label>
                            </Col>

                            <Col sm={1} style={{ textAlign: 'end', maxWidth:'100px' }}>
                                <label style={{ color: 'white' }}>Quiz Title</label>
                            </Col>
                            <Col sm={2}>
                                <FormGroup>

                                    <Input
                                        defaultValue={currentQuiz && currentQuiz.title }
                                        innerRef={register({ required: true })}
                                        name='quizTitle'
                                        placeholder="Enter Quiz Title"
                                        type="text"
                                        // required
                                    />
                                {errors.quizTitle && errors.quizTitle.type === "required" && notify("Quiz Title field can't be empty",true)}
                                </FormGroup>
                            </Col>
                            <Col sm={1} style={{ textAlign: 'end', maxWidth:'135px' }}>
                                <label style={{ color: 'white' }}>Time/Question</label>
                            </Col>  
                                
                            {
                                iswithoutTime===false ?(

                                    <Col sm={1}>
                                        <FormGroup>
                                            <Input
                                                defaultValue={currentQuiz && currentQuiz.timePerQues }
                                                innerRef={register({ required: true })}
                                                name='time'
                                                placeholder="sec"
                                                type="number"
                                                // required
                                            />
                                        </FormGroup>
                                        {errors.time && errors.time.type === "required" && notify("Time/Question field can't be empty",true)}

                                    </Col>
                                    ):(
                                        <Col sm={1} style={{ textAlign: 'end', maxWidth:'135px' }}>
                                            <label style={{color:'grey',border:'1px solid #38385d',borderRadius:'5px',padding:'8px 10px',fontSize:'12px'}} >Unlimited</label>
                                        </Col>  
                                    )

                            }
                            
                            <Col sm={1} >
                                {/* <label for='timer'> </label> */}
                                <Switch
                                    color='info'
                                    checked={iswithoutTime}
                                    onChange={(e)=>{
                                    setIswithoutTime(e.target.checked)
                                    console.log(e.target.checked)}}/>
                            </Col>  
                            <Col sm={1} style={{ textAlign: 'end', maxWidth:'75px' }}>
                                <label style={{ color: 'white' }}>Rating</label>
                            </Col>
                            <Col sm={1} >
                                <FormGroup>
                                    <Input
                                        defaultValue={currentQuiz && currentQuiz.rating }
                                        innerRef={register({ required: true,min:0,max:5 })}
                                        name='rating'
                                        placeholder="max 5"
                                        type="number"
                                        // required
                                    />
                                    {errors.rating && errors.rating.type === "required" && notify("Rating field can't be empty",true)}
                                    {errors.rating && errors.rating.type === "max" && notify('Rating should be out of 5',true)}
      
                                </FormGroup>

                            </Col>
                            <Col sm={1} style={{ marginLeft: '20px' }}>
                                <Button color='info' type='submit'>Submit</Button>

                            </Col>
                        </Row>
                    </Form>

                </Row>
            }

        </div >
    )
}
export default QuizDialog