
import React, { useState } from "react";
import { useForm } from 'react-hook-form'

// import Select from 'react-select';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";
import { db } from '../db/config'
//installed this library
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';


function Edit_User({ myUser, onClick ,setUserData,notify}) {
  const { register, handleSubmit, errors } = useForm()

  //added this for the options 
  const options = [
    // { label: 'Dashboard', value: 'Dashboard' },
    { label: 'User Management', value: 'User_Management' },
    { label: 'Student Management', value: 'Student_Management' },
    { label: 'Book Management', value: 'Book_Management' },
    { label: 'Quiz Management', value: 'Quiz_Management' },
    { label: 'Package Management', value: 'Package_Management' },
    { label: 'Transaction Management', value: 'Transaction_Management' },
    { label: 'Chat', value: 'Chat' },
    // { label: 'Reports', value: 'Reports' },
    


  ];
  //added this for the state to pass to firebase
  const [selectedPermission, setselectedPermission] = useState(myUser.permissions)


  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#272a3d' : 'black',
      padding: 10,
    }),
    control: () => ({

      display: 'none'
    }),

  }
  return (

    <div className="content" >
     
      <Row >
        <Col>
          <Card style={{ margin: 0 }}>
            <CardHeader>
              <h5 className="title">Edit User</h5>
            </CardHeader>
            <CardBody>
              <Form style={{}} onSubmit={handleSubmit(data => {
                const updatedData={
                  firstname: data.firstname,
                  lastname: data.lastname,
                  mobile: data.mobile,
                  email: data.email,
                  
                  disabled:myUser.disabled,
                  // status:myUser.status,
                  permissions: selectedPermission,
                  password: data.password,
                  username: data.username,
                }

                db.collection('zSystemUsers').doc(myUser._uniqueID.toString()).update(updatedData).then(function () {
                  // console.log('documnet successfully inserted')
                  onClick()
                  notify("User Updated Successfully",false)
                  setUserData(prev=> prev.map((item) => item._uniqueID == myUser._uniqueID ? ({...updatedData, _uniqueID: myUser._uniqueID }) : item))
                  // console.log('sucesssssssssss',updatedData)
                }).catch(function (error) {
                  notify(error.toString(),true)
                })

              }
              )}
              >
                <Row className='lg' md='1'>

                  <Col className="lg" md="3">
                    <FormGroup>
                      <label>FirstName</label>
                      <Input
                        defaultValue={myUser.firstname}
                        innerRef={register({ required: true })}
                        name='firstname'
                        placeholder="FirstName"
                        type="text"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col className="lg" md="3">
                    <FormGroup>
                      <label>LastName</label>
                      <Input
                        defaultValue={myUser.lastname}
                        innerRef={register}
                        name='lastname'
                        placeholder="LastName"
                        type="text"

                      />
                    </FormGroup>
                  </Col>
                  <Col className="pl-md-1" md="4">
                    <FormGroup>
                      <label>
                        Username
                      </label>
                      <Input
                        defaultValue={myUser.username}
                        placeholder="username"
                        type="text"
                        name='username'
                        innerRef={register({ required: true, minLength: 6})} />
                        {errors.username && errors.username.type === 'minLength' && (
                        <span style={{fontSize:'10px',position:'absolute',bottom:'-20px',color:'red'}} >Password should be atleast 6 digits.</span>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>Email</label>
                      <Input
                        defaultValue={myUser.email}
                        innerRef={register({ required: true, })}
                        name='email'
                        placeholder="user@user.com"
                        type="email"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pl-md-1" md="6">
                    <FormGroup>
                      <label>Password</label>
                      <Input
                        defaultValue={myUser.password}
                        innerRef={register({ required: true, minLength: 6 })}
                        required
                        name='password'
                        type="password"
                        placeholder='Password'
                      />
                      {errors.password && errors.password.type === 'minLength' && (
                        <span style={{fontSize:'10px',position:'absolute',bottom:'-20px',color:'red'}} >Password should be atleast 6 digits.</span>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>Mobile</label>
                      <Input
                        defaultValue={myUser.mobile}
                        innerRef={register({ minLength: 10, maxLength: 10, required: true })}
                        name='mobile'
                        placeholder="Mobile"
                        type="mobile"
                        required
                      />
                      
                      {errors.mobile && errors.mobile.type !== 'required' &&
                        <span style={{fontSize:'10px',position:'absolute',bottom:'-20px',color:'red'}}  >Please Enter Valid Mobile No.</span>
                      }
                    </FormGroup>
                  </Col>
                  <Col className="pl-md-1" md="6">
                    <FormGroup>
                      <label>Account Permission</label>
                      {/* Added this library */}
                      <ReactMultiSelectCheckboxes
                        defaultValue={selectedPermission}
                        styles={customStyles}
                        onChange={(option) => {
                          setselectedPermission(option)
                          // console.log(`Option selected:`, option);
                        }}
                        options={options} />

                    </FormGroup>
                  </Col>
                  <Col className="pl-md-1" md="6">
                    {/* <FormGroup>
                          <label style={{marginLeft:10}}>User Role</label>
                          
                         <Input defaultValue={myUser.role} type='select' name='role' innerRef={register({required:true})}>
                           <option>admin</option>
                           <option>superadmin</option>
                         </Input>
                        </FormGroup> */}
                  </Col>
                </Row>
                <div>
                  <Button className="btn-fill" color="info" type="submit">Save</Button>
                  <Button className="btn-fill" onClick={onClick}>Back</Button>
                  {/* {visible === false ? <Button className="btn-fill" color="info" type="submit">
                    Save
                  </Button> : <Button onClick={onClick} className="btn-fill" color="info">Done</Button>}
                  {visible === false && <Button className="btn-fill" onClick={onClick}>Back</Button>} */}
                </div>
              </Form>
            </CardBody>

          </Card>
        </Col>

      </Row>
    </div>

  );
}


export default Edit_User;
