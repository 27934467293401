import { Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";
import { Bar } from "react-chartjs-2";
import { Row, Col, ButtonGroup,  } from "reactstrap";
import TopicAnalyticsDetailDialog from "./TopicAnalyticsDetailDialog";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TopicAnalytics = () => {
  const [userData, setUserData] = useState([]);
  const [topics, setTopics] = useState([]);
  const [open, setOpen] = useState(false);
  const [element, setElement] = useState();
  const [studentLevel, setStudentLevel] = useState(false);

  const handleClose = () => {
    setOpen(!open);
  };

  const getTopics = () => {
    return new Promise((res, rej) => {
      db.collection("zSystemStore")
        .where("type", "==", "topic")
        .get()
        .then((data) => {
          let topicsArr = [];
          data.forEach((doc) => {
            topicsArr.push({ ...doc.data(), totalTime: 0 });
          });
          console.log("topicsArr", topicsArr);
          res(topicsArr);
        });
    });
  };

  const getUsers = () => {
    return new Promise((res, rej) => {
      db.collection("zSystemStudents")
        .where("timeSpent", "!=", "null")
        .get()
        .then(function (querySnapshot) {
          var students = [];
          querySnapshot.forEach(function (doc) {
            students.push(doc.data());
          });
          console.log("Students", students);
          res(students);
        });
    });
  };

  const calculateTopicHours = ([tempTopics, userData]) => {
    tempTopics = tempTopics.map((topic) => {
      userData.map((user) => {
        user.timeSpent[topic.itemID] &&
          (topic.totalTime =
            parseInt(topic.totalTime) + parseInt(user?.timeSpent[topic.itemID]));
            
      });
      console.log("topic.timeSpent",topic?.totalTime)
      return topic;
    });
    console.log("tempTopics",tempTopics)
    setTopics(tempTopics);
  };

  useEffect(() => {
    Promise.all([getTopics(), getUsers()]).then((response) => {
      setUserData(response[1]);
      calculateTopicHours(response);
    });
  }, []);

  const handleClick = (element) => {
    setOpen(true);
    setElement(element);
  };

  let topicData = {
    data: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(72,72,176,0.1)");
      gradientStroke.addColorStop(0.4, "rgba(72,72,176,0.0)");
      gradientStroke.addColorStop(0, "rgba(119,52,169,0)");

      return {
        labels: topics.map((topic) => topic.title),
        datasets: [
          {
            label: "Time Spent",
            fill: true,
            backgroundColor: gradientStroke,
            hoverBackgroundColor: gradientStroke,
            borderColor: "#d048b6",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: topics.map((topic) => topic.totalTime),
          },
        ],
      };
    },
    options: {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            gridLines: {
              drawBorder: false,
              color: "rgba(225,78,202,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              suggestedMin: 50,
              suggestedMax: 100,
              padding: 20,
              fontColor: "#9e9e9e",
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              drawBorder: false,
              color: "rgba(225,78,202,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              padding: 20,
              fontColor: "#9e9e9e",
            },
          },
        ],
      },
      onClick: (e, element) => {
        handleClick(element);
      },
    },
  };

  return (
    <div className="content">
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <TopicAnalyticsDetailDialog
          element={element}
          userData={userData}
          handleClose={handleClose}
          topics={topics}
        />
      </Dialog>
     
      <Row>
        {studentLevel ? (
          <Col xs={12}></Col>
        ) : (
          <Col xs="12">
            <Bar
              data={topicData.data}
              height={450}
              options={topicData.options}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default TopicAnalytics;
