
import React,{useState} from "react";
import {useForm} from 'react-hook-form'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

import * as firebase from 'firebase'

const functions = firebase.functions()


function Edit_Student({notify,userData,setUserData,onClick}){
    // const [visible,setVisible] = useState(false)
    const [error,setError] = useState('')
    const {register,handleSubmit,errors} = useForm()
    
    // console.log(firstname,lastname,mobile,status)
    return (
      <>
        <div className="content" style={{width:1150,}}>
          <Row style={{}}>
            <Col md="8">
              <Card style={{border:'2px solid white'}}>
                <CardHeader>
                  <h5 className="title">Edit Student</h5>
                </CardHeader>
                <CardBody>
                  <Form style={{paddingRight:10}} onSubmit={handleSubmit(data=>{
                     
                    // setVisible(true)
                    // console.log('form submitted')
                    console.log('data',data)
                    // console.log(data.status)
                    // console.log(data.firstname)
                    // console.log(data.lastname)
                        if((data.countryCode == '+91' && data.mobile.length !== 10 ) || (data.countryCode == '+27' && data.mobile.length !== 9)){
                             setError('invalid mobile number')
                             console.log('In If')
                             notify('Invalid mobile number',true)  

                           }else{
                             onClick()
                             console.log('In else')
                             setError('')
                              const finalData={
                                userID:userData._uniqueID,
                                firstname:data.firstname,
                                lastname:data.lastname,
                                mobile:data.mobile,
                                status:data.status,
                                address:userData.address,
                                createdFrom:userData.createdFrom,
                                disabled:userData.disabled,
                                email:userData.email,
                                password:userData.password,
                                profilePic:userData.profilePic,
                                _uniqueID:userData._uniqueID,
                                lastUpdatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                                countryCode: data.countryCode

                              }
                              document.querySelector("#loading-indicator").style.display = "flex";

                              const updateStudent = functions.httpsCallable('updateStudent');
                              updateStudent(finalData).then(result =>{
                              console.log('result',result)
                              if(result.data.hasError === false){
                                document.querySelector("#loading-indicator").style.display = "none";

                                setUserData(prev => prev.map((item) => item._uniqueID == userData._uniqueID ? finalData : item))
                                notify('Student details edited successfully',false)  
                              }else{
                                document.querySelector("#loading-indicator").style.display = "none";

                                notify(result.data.message.toString(),true)  

                              }

                              }).catch(err=>{
                                
                                notify(err.toString(), true);
                                document.querySelector("#loading-indicator").style.display = "none";
                                console.log(err.toString())
                              })
      
                            // db.collection('zSystemStudents').doc(userData._uniqueID).update().then(function(){
                            //   notify('Student details edited successfully',false)  
                            //   console.log('documnet successfully inserted')
                            // }).catch(function(error){
                            //   notify(error,true)
                            //   console.log('this is error', error)
                            // })
                           }
                       }
                  )}   >
                     <Row >
                       <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>First Name</label>
                          <Input
                            defaultValue={userData.firstname}
                            innerRef={register({required:true})}
                            name='firstname'
                            placeholder="FirstName"
                            type="text"
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Last Name</label>
                          <Input
                            defaultValue={userData.lastname}
                            innerRef={register}
                            name='lastname'
                            placeholder="LastName"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      {/* <Col className="pl-md-1" md="4">
                        <FormGroup>
                          <label>
                            Username
                          </label>
                          <Input placeholder="username" type="text" name='username' required innerRef={register({required:true,minLength:6})}/>
                        </FormGroup>
                      </Col> */}
                    </Row>
                    
                    <Row>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Mobile</label>
                          <Row>
                          <Col md="4">
                            <Input
                              defaultValue={userData?.mobile !== undefined && userData?.mobile?.length == 9 ? '+27' : '+91'}
                              
                              innerRef={register({required:true,})}
                              name='countryCode'
                              placeholder="Status"
                              type="select"
                              required
                            > 
                              <option>+27</option>
                              <option>+91</option>
                              
                            </Input>
                          </Col>
                          <Col md="8">
                          <Input
                            defaultValue={userData.mobile}
                            innerRef={register({minLength:9,maxLength:10,required:true,})}
                            name='mobile'
                            placeholder="Mobile"
                            type='number'
                            required
                          />
                          </Col>
                        </Row>  
                          {errors.mobile && errors.mobile.type !== 'required' &&
                            <span style={{fontSize:'10px',position:'absolute',bottom:'-20px',color:'red'}}  >Please Enter Valid Mobile No.</span>
                          }
                        </FormGroup>
                      </Col>
                      {/* <Col>
                          <FormGroup>
                          <label>Enrollment number</label>
                          <Input
                            defaultValue={userData.enrollment}
                            innerRef={register({ required: true })}
                            name='enrollment'
                            placeholder="Enrollment number"
                            type='text'
                            required
                          />
                          {errors.enrollment && errors.enrollment.type === 'required' &&
                            <span style={{fontSize:'10px',position:'absolute',bottom:'-20px',color:'red'}}  >Please enter enrollment number</span>
                          }
                          </FormGroup>
                      </Col> */}
                      
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Status</label>
                          <Input
                            defaultValue={userData.status}
                            
                            innerRef={register({required:true,})}
                            name='status'
                            placeholder="Status"
                            type="select"
                            required
                          > 
                            {/* <option>Onboarding</option> */}
                            <option>Active</option>
                            {/* <option>Payment pending</option> */}
                            {/* <option>Limited access</option> */}
                            <option>Inactive</option>
                            {/* <option>Archived</option> */}

                          </Input>
                        </FormGroup>
                      </Col> 
                    </Row>
                    
                    <div style={{display:'flex' , flexDirection:'row'}}>
                       
                    {/* {visible===false ?<Button className="btn-fill" color="info" type="submit">
                    Submit
                  </Button> : <Button className="btn-fill" color="info" onClick={onClick}>
                    Done
                  </Button>}
                  {visible===false&& <Button className="btn-fill"  onClick={onClick}>
                    Cancel
                  </Button>} */}

                  {/* <Button color='info' type="submit"> 
                 <NavLink to={{
            pathname:'/admin/student',
            
          }}
          
          style={{color:'white'}}> Update </NavLink>
                  </Button> */}
             <Button color='info' type="submit" style={{marginRight:5}}>Update</Button>
                  <Button  onClick={onClick}>Back</Button>
                    </div>
                  </Form>
                </CardBody>
               
              </Card>
            </Col>
            
          </Row>
        </div>
      </>
    );
  }


export default Edit_Student;
